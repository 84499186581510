import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

// Pages Component
import Chat from "../pages/Chat/Chat";

//Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/jquery-knob";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";

//Icons
import RemixIcons from "../pages/Icons/RemixIcons";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";

//Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UIRoundSlider from "../pages/Ui/UIRoundSlider";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

//Kanban Board
import KanbanBoard from "../pages/KanbanBoard/index";
import VendorRequests from "../pages/VendorRequests";
import Categories from "../pages/Categories";
import SubCategories from "../pages/SubCategories";
import AddNewCategory from "../pages/Categories/AddNewCategory";
import AddNewSubCategory from "../pages/SubCategories/AddNewCategory";
import Products from "../pages/Products";
import AddProduct from "../pages/Products/AddProduct";
import WithdrawalVendor from "../pages/WidthrawalVendor";
import VendorPickup from "../pages/VendorPickup";
import VendorQuries from "../pages/VendorQueries";
import VendorCoupons from "../pages/Coupons";
import WithdrawalAdmin from "../pages/WidthrawalAdmin";
import AdminQueries from "../pages/AdminSupportTickets";
import AdminLocationManagement from "../pages/AdminLocationManagement";
import PackageSizes from "../pages/PackageSizes";
import ShipmentCharges from "../pages/ShipmentCharges";
import WarehouseAddress from "../pages/WarehouseAddress";
import AdminCoupons from "../pages/CouponsAdmin";
import InsuranceCharges from "../pages/InsuranceCharges";
import ShipmentDuration from "../pages/ShipmentDuration";
import ShipmentOrders from "../pages/ShipmentOrders";
import UsersComponent from "../pages/Users";
import PackageEnquiry from "../pages/PackageEnquiry";
import VendorCommission from "../pages/VendorCommission";
import VendorEcommerceOrders from "../pages/VendorEcommerceOrder";
import VendorDashboard from "../pages/VendorDashboard";
import AddEcomCategory from "../pages/EcomCategories/AddNewCategory";
import EcomCategories from "../pages/EcomCategories";
import EcomSubCategories from "../pages/EcomSubCategories";
import AddEcomSubCategory from "../pages/EcomSubCategories/AddNewCategory";
import AdminEcommerceOrder from "../pages/AdminEcommerceOrder";
import AdminEcommerceDashboard from "../pages/AdminEcomDashboard";
import AdminShipmentDashboard from "../pages/AdminShipmentDashboard";
import ContentManagementWebsite from "../pages/ContentManagementWebsite";
import ContentManagementMobile from "../pages/ContentManagementMobile";
import FAQS from "../pages/FAQ";
import VendorTicketChat from "../pages/VendorTicketChat";
import EmailTemplates from "../pages/EmailTemplates";
import AddNewPageWeb from "../pages/AddNewPageWeb";
import AddNewImageCategory from "../pages/AddNewImageCategory"
import AddNewOfferPageWeb from "../pages/AddNewOfferPageWeb";
import AddNewOfferPageWebSecond from "../pages/AddNewOfferPageWebSecond";
import AddNewOfferPageMobile from "../pages/AddNewOfferMobile";
import AddNewPageMobile from "../pages/AddNewPageMobile";
import ShippingCalculator from "../pages/ShippingCalculator";
import Umbrella from "../pages/Umbrella";
import CreateNewUmbrella from "../components/CreateNewUmbrella";
import ContainerOrders from "../pages/Umbrella/ContainerOrders";
import EcomChilCategories from "../pages/EcomChildCategories/index"
import AddNewChildCategories from "../pages/EcomChildCategories/AddNewChildCategories";
import VendorPlatformFee from "../pages/VendorPlatformFee/VendorPlatformFee";
import UpdateCommission from "../pages/VendorCommission/UpdateCommission";
import EcomProductManagement from "../pages/EcomProductManagement/EcomProductManagement";
import VendorRevenueReport from "../pages/VendorRevenueReport/VendorRevenueReport";
import UnderProcessScreen from "../pages/UnderProcessScreen/UnderProcessScreen";
import AddNewTitle from "../pages/ContentManagementWebsite/AddNewTitle";
import VendorStore from "../pages/VendorStore/VendorStore";
import AddNewSliderVendorStore from "../pages/VendorStore/AddNewSliderVendorStore";
import AddLogoVendorStore from "../pages/VendorStore/AddLogoVendorStore";
import SupportAdmin from "../pages/Support/index";
import SupportChat from "../pages/Support/SupportChat";
import PromotionalNotification from "../pages/PromotionalNotification";
import NotificationBar from "../pages/NotificationBar";

function RedirectUserOrVendorDashBoard() {
	const isAdmin =
	JSON.parse(localStorage.getItem("auth_info"))?.isAdmin || null;

  return isAdmin? (
    <Redirect to="/dashboard" />
  ) : (
    <Redirect to="/vendor-dashboard" />
  );
}

const authProtectedRoutes = [
  //Kanban Board
  { path: "/kanban-board", component: KanbanBoard },
  { path: "/umbrella", component: Umbrella },
  { path: "/container-orders", component: ContainerOrders },
  { path: "/umbrella-new", component: CreateNewUmbrella },
  { path: "/email-templates", component: EmailTemplates },
  { path: "/add-new-page-web", component: AddNewPageWeb },
  { path: "/add-new-image-category", component: AddNewImageCategory },
  { path: "/add-new-products-title", component: AddNewTitle },
  { path: "/add-new-page-mobile", component: AddNewPageMobile },
  { path: "/add-new-offer-page-web", component: AddNewOfferPageWeb },
  {
    path: "/add-new-offer-page-web-second",
    component: AddNewOfferPageWebSecond,
  },
  { path: "/support-admin", component: SupportAdmin },
  { path: "/add-new-offer-page-mobile", component: AddNewOfferPageMobile },
  { path: "/shipping-calculator", component: ShippingCalculator },

  // Tables
  { path: "/basic-tables", component: BasicTables },
  { path: "/datatable-table", component: DatatableTables },
  { path: "/responsive-table", component: ResponsiveTables },
  { path: "/editable-table", component: EditableTables },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  // { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-roundslider", component: UIRoundSlider },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-file-upload", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  //Utility
  { path: "/starter", component: StarterPage },
  { path: "/timeline", component: Timeline },
  // { path: "/faqs", component: FAQs },
  { path: "/pricing", component: Pricing },

  //Icons
  { path: "/icons-remix", component: RemixIcons },
  { path: "/material-design", component: MaterialDesign },
  { path: "/dripicons", component: DripiIcons },
  { path: "/font-awesome-5", component: FontAwesome },

  // Maps
  { path: "/google-maps", component: MapsGoogle },
  { path: "/vector-maps", component: MapsVector },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartjs", component: ChartjsChart },
  { path: "/charts-sparkline", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },
  { path: "/users", component: UsersComponent },

  //Ecommerce

  { path: "/ecommerce-products", component: EcommerceProducts },
  { path: "/ecommerce-product-detail/:id", component: EcommerceProductDetail },
  { path: "/ecommerce-orders", component: EcommerceOrders },
  { path: "/vendor-requests", component: VendorRequests },
  { path: "/management-admin-withdarawal", component: WithdrawalAdmin },
  { path: "/admin-queries", component: AdminQueries },
  { path: "/promitional-notification", component: PromotionalNotification },
  { path: "/notification-history", component: NotificationBar },
  { path: "/location-management", component: AdminLocationManagement },
  { path: "/pickup", component: VendorPickup },

  { path: "/coupons", component: VendorCoupons },
  { path: "/coupon-admin", component: AdminCoupons },
  { path: "/categories", component: Categories },
  { path: "/ecom-categories", component: EcomCategories },
  { path: "/ecom-sub-categories", component: EcomSubCategories },
  { path: "/ecom-child-categories", component: EcomChilCategories },
  { path: "/add-new-category", component: AddNewCategory },
  { path: "/add-new-ecom-category", component: AddEcomCategory },
  { path: "/add-new-ecom-sub-category", component: AddEcomSubCategory },
  { path: "/add-new-ecom-child-category", component: AddNewChildCategories },
  { path: "/sub-category", component: SubCategories },
  { path: "/add-new-sub-category", component: AddNewSubCategory },
  { path: "/shipment-charges", component: ShipmentCharges },
  { path: "/under-process", component: UnderProcessScreen },

  { path: "/warehouse-address", component: WarehouseAddress },
  { path: "/package-sizes", component: PackageSizes },
  { path: "/ecommerce-customers", component: EcommerceCustomers },
  { path: "/ecommerce-cart", component: EcommerceCart },
  { path: "/content-management-website", component: ContentManagementWebsite },
  { path: "/faqs", component: FAQS },
  { path: "/content-management-mobile", component: ContentManagementMobile },
  { path: "/ecommerce-checkout", component: EcommerceCheckout },
  { path: "/ecommerce-shops", component: EcommerceShops },
  { path: "/ecommerce-add-product", component: EcommerceAddProduct },
  { path: "/insurance-charge", component: InsuranceCharges },
  { path: "/shipment-duration", component: ShipmentDuration },
  { path: "/handle-vendor-commission", component: VendorCommission },
  { path: "/admin-ecommerce-orders", component: AdminEcommerceOrder },
  { path: "/product-management", component: EcomProductManagement },
  { path: "/vendor-revenue-report", component: VendorRevenueReport },
  { path: "/update-commission", component: UpdateCommission },
  { path: "/admin-ecommerce-dashboard", component: AdminEcommerceDashboard },
  { path: "/shipment-orders", component: ShipmentOrders },
  { path: "/shipment-enquiries", component: PackageEnquiry },

  //chat
  { path: "/chat", component: Chat },

  { path: "/support-ticket", component: VendorTicketChat },
  { path: "/support-customer", component: SupportChat },
  //calendar
  { path: "/calendar", component: Calendar },

  { path: "/dashboard", component: AdminShipmentDashboard },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <RedirectUserOrVendorDashBoard />,
  },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/lock-screen", component: AuthLockScreen },

  // Authentication Inner
  { path: "/auth-login", component: Login1 },
  { path: "/auth-register", component: Register1 },
  { path: "/auth-recoverpw", component: ForgetPwd1 },

  { path: "/maintenance", component: Maintenance },
  { path: "/comingsoon", component: CommingSoon },
  { path: "/404", component: Error404 },
  { path: "/500", component: Error500 },
];
const vendorRoutes = [
  { path: "/vendor-dashboard", component: VendorDashboard },
  { path: "/manage-products", component: Products },
  { path: "/store-management", component: VendorStore },
  { path: "/vendor-withdarawal", component: WithdrawalVendor },
  { path: "/vendor-ecommerce-orders", component: VendorEcommerceOrders },
  { path: "/vendor-platform-free", component: VendorPlatformFee },
  { path: "/add-new-product", component: AddProduct },
  { path: "/support-ticket", component: VendorTicketChat },
  { path: "/support", component: VendorQuries },
  { path: "/store/:vendorName", component: UnderProcessScreen },
  { path: "/add-new-slider-vendor-store", component: AddNewSliderVendorStore },
  { path: "/add-logo-vendor-store", component: AddLogoVendorStore },
  { path: "/notification-history", component: NotificationBar },
];

export { authProtectedRoutes, publicRoutes, vendorRoutes };
