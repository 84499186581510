import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { put } from "../helpers/api_helper";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import {
  selectCategoryToUpdate,
  selectProductToUpdate,
} from "../store/userAuthInfo/actions";
import { productVariantValidationCheck } from "../store/layout/actions";
import { useHistory } from "react-router-dom";
function ProductActionDropdown({ row, getAllVendorRequests, categories }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const navigation = useHistory();
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);
    updateVendorRequestStatus(item);
  };
  async function updateVendorRequestStatus(status) {
    try {
      await put(`/api/product/${row.id}`, {
        isActive: status,
      });
      toastr.success("Status updated!", "Success");
      getAllVendorRequests();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
      <DropdownToggle caret className="primary" color="primary">
        <i className="mdi mdi-menu font-size-18"></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => handleItemClick(true)}>
          <i className="mdi mdi-checkbox-marked-circle font-size-18 me-2"></i>
          ACTIVE
        </DropdownItem>
        <DropdownItem onClick={() => handleItemClick(false)}>
          <i className="mdi mdi-eye-off font-size-18 me-2"></i>HIDE
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            dispatch(selectProductToUpdate({ row }));
            dispatch(productVariantValidationCheck(false));
            navigation.push("/add-new-product");
          }}
        >
          <i className="mdi mdi-pencil font-size-18 me-2"></i>EDIT
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default ProductActionDropdown;
