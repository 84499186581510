import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
// import logodark from "../../assets/images/Shipora.png";
import logodark from "../../assets/logo/web_logo_2.png";
import logolight from "../../assets/logo/web_logo_2.png";
import { post } from "../../helpers/api_helper";
import { addUserInfo } from "../../store/userAuthInfo/actions";

const Login = () => {
  const navigation = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loginSuccess, setLoginSuccess] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpModelShow, setOtpModelShow] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (event, values) => {
    try {
      const responseObj = await post("/api/auth/vendor-signin", {
        email: username,
        password,
      });
      await localStorage.setItem("auth_token", responseObj.accessToken);
      await localStorage.setItem("auth_info", JSON.stringify(responseObj));
      window.setTimeout(() => {
        dispatch(addUserInfo(responseObj));
        setLoginSuccess(responseObj.message);
        setLoginError(null);

        if (responseObj.isAdmin) {
          navigation.push("/dashboard");
        } else {
          navigation.push("/vendor-dashboard");
        }
      }, 2500);
    } catch (e) {
      setLoginSuccess(null);
      setLoginError(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  };

  const toggleForgotPasswordModal = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal);
    setEmail("");
    setNewPassword("");
    setConfirmPassword("");
  };
  const [showAddNewPasswordModel, setShowAddNewPasswordModel] = useState(false);
  const toggleShowAddNewPasswordModel = () => {
    setShowAddNewPasswordModel(!showAddNewPasswordModel);
  };

  const handleResetPassword = async () => {
    try {
      if (!email) {
        toastr.error("please enter email");
        return;
      }

      let responce = await post("/api/forgot-password-vendor", {
        email,
        // new_password: newPassword,
        // confirm_password: confirmPassword,
      });
      if (responce.status) {
        toastr.success(responce.message);
        setOtpModelShow(true);
        setShowForgotPasswordModal(!showForgotPasswordModal);
      }
    } catch (e) {
      toastr.error(e?.response?.data?.message);
      // setLoginError(e?.response?.data?.message || "something went wrong");
    }

    // setTimeout(() => toggleForgotPasswordModal(), 2000); // Close modal after success
  };
  const confirmPasswordSubmit = async () => {
    try {
      if (!email) {
        toastr.error("please enter email");
        return;
      }
      if (!newPassword) {
        toastr.error("please enter new password");
        return;
      }
      if (!confirmPassword) {
        toastr.error("please enter confirm password");
        return;
      }
      if (newPassword !== confirmPassword) {
        toastr.error("new password and confirm password is not match");
        return;
      }
      let responce = await post("/api/forgot-vendor-password-change", {
        email,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });
      if (responce.status) {
        toastr.success(responce.message);
        setShowAddNewPasswordModel(!showAddNewPasswordModel);
      }
    } catch (e) {
      toastr.error(e?.response?.data?.message);
      console.log(e.response.data.message);
    }
  };
  const toggleOtpModal = () => {
    setOtp(["", "", "", ""]);
    setOtpModelShow(!otpModelShow);
  };
  const resetOtpClick = () => {
    setOtp(["", "", "", ""]);
  };
  const handleOtp = async () => {
    try {
      let response = await post("/api/otp-verification-vendor", {
        email,
        otp: otp.join(""),
        new_password: newPassword,
      });
      if (response.status) {
        toastr.success(response.message);
        setShowAddNewPasswordModel(!showAddNewPasswordModel);
        setOtpModelShow(false);
        setOtp(["", "", "", ""]);
      }
    } catch (error) {
      toastr.error(error.response.data.message);
      console.log(error.response.data.message);
    }
  };
  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const newOtp = [...otp];

    // If the value is a digit, update the OTP array
    if (/^\d$/.test(value)) {
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus on the next input field if the current field is filled
      if (index < otp.length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    } else if (value === "") {
      // If the value is empty, remove the digit and focus on the previous input
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");

    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, []);

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col
              lg={4}
              className="scrollable-content py-5"
              style={{ overflowY: "auto" }}
            >
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="">
                              <img
                                src={logodark}
                                alt=""
                                // height="150px"
                                className="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src={logolight}
                                alt=""
                                height="40"
                                className="auth-logo logo-light mx-auto"
                              />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                          <p className="text-muted">
                            Sign in to continue to Shipora.
                          </p>
                        </div>

                        {loginError && loginError ? (
                          <Alert color="danger">{loginError}</Alert>
                        ) : null}
                        {loginSuccess && loginSuccess ? (
                          <Alert color="success">{loginSuccess}</Alert>
                        ) : null}

                        <div className="p-2 mt-5">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleSubmit}
                          >
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="username">
                                Email or Username
                              </Label>
                              <AvField
                                name="username"
                                value={username}
                                type="text"
                                className="form-control"
                                id="username"
                                validate={{ required: true }}
                                placeholder="Enter email or username"
                                onChange={(e) => setUsername(e.target.value)}
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="userpassword">Password</Label>
                              <AvField
                                name="password"
                                value={password}
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>

                            <div
                              className="form-check"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </Label>
                              </div>

                              <div>
                                <Label
                                  className="form-check-label"
                                  htmlFor="forgot-password"
                                  onClick={toggleForgotPasswordModal}
                                >
                                  Forgot Password
                                </Label>
                              </div>
                            </div>

                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </Button>
                            </div>
                          </AvForm>
                        </div>

                        <div className="mt-5 text-center">
                          <p>
                            Don't have an account ?{" "}
                            <Link
                              onClick={() => {
                                window.location =
                                  "https://vendor-registration.shipora.io/vendor-registration";
                              }}
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Register{" "}
                            </Link>
                          </p>
                          <p>
                            © {new Date().getFullYear()} Crafted with{" "}
                            <i className="mdi mdi-heart text-danger"></i> by
                            Shipora.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Forgot Password Modal */}
      <Modal
        isOpen={showForgotPasswordModal}
        toggle={toggleForgotPasswordModal}
      >
        <ModalHeader toggle={toggleForgotPasswordModal}>
          Forgot Your Password
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="mb-3">
              <Label>Email</Label>
              <Input
                type="email"
                value={email}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {/* <div className="mb-3">
              <Label>New Password</Label>
              <Input
                type="password"
                value={newPassword}
                placeholder="Enter new password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <Label>Confirm Password</Label>
              <Input
                type="password"
                value={confirmPassword}
                placeholder="Confirm new password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div> */}
            <div className="text-end">
              <Button
                color="secondary"
                className="me-2"
                onClick={toggleForgotPasswordModal}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={handleResetPassword}>
                Send Otp
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal isOpen={otpModelShow} toggle={toggleOtpModal}>
        <ModalHeader toggle={toggleOtpModal}>Enter OTP</ModalHeader>
        <ModalBody>
          <form>
            <div className="mb-3">
              {/* <Label>Enter OTP</Label> */}
              <div
                className="d-flex justify-content-between"
                style={{ width: "55%", margin: "auto" }}
              >
                <Input
                  id="otp-0" // Add ID for easy reference
                  type="text"
                  maxLength="1"
                  value={otp[0]}
                  onChange={(e) => handleOtpChange(e, 0)}
                  style={{
                    height: "55px",
                    width: "55px",
                    fontSize: "30px",
                    textAlign: "center",
                  }}
                  className="otp-input"
                  autoFocus
                />
                <Input
                  id="otp-1" // Add ID for easy reference
                  type="text"
                  maxLength="1"
                  value={otp[1]}
                  onChange={(e) => handleOtpChange(e, 1)}
                  className="otp-input"
                  style={{
                    height: "55px",
                    width: "55px",
                    fontSize: "30px",
                    textAlign: "center",
                  }}
                />
                <Input
                  id="otp-2" // Add ID for easy reference
                  type="text"
                  maxLength="1"
                  value={otp[2]}
                  onChange={(e) => handleOtpChange(e, 2)}
                  className="otp-input"
                  style={{
                    height: "55px",
                    width: "55px",
                    fontSize: "30px",
                    textAlign: "center",
                  }}
                />
                <Input
                  id="otp-3" // Add ID for easy reference
                  type="text"
                  maxLength="1"
                  value={otp[3]}
                  onChange={(e) => handleOtpChange(e, 3)}
                  className="otp-input"
                  style={{
                    height: "55px",
                    width: "55px",
                    fontSize: "30px",
                    textAlign: "center",
                  }}
                />
              </div>
            </div>
            <div className="text-center">
              <Button
                color="secondary"
                className="me-2"
                onClick={resetOtpClick}
              >
                Reset
              </Button>
              <Button color="primary" onClick={handleOtp}>
                Verify OTP
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showAddNewPasswordModel}
        toggle={toggleShowAddNewPasswordModel}
      >
        <ModalHeader toggle={toggleShowAddNewPasswordModel}>
          Forgot Your Password
        </ModalHeader>
        <ModalBody>
          <form>
            {/* <div className="mb-3">
              <Label>Email</Label>
              <Input
                type="email"
                value={email}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div> */}
            <div className="mb-3">
              <Label>New Password</Label>
              <Input
                type="password"
                value={newPassword}
                placeholder="Enter new password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <Label>Confirm Password</Label>
              <Input
                type="password"
                value={confirmPassword}
                placeholder="Confirm new password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="text-end">
              <Button
                color="secondary"
                className="me-2"
                onClick={toggleShowAddNewPasswordModel}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={confirmPasswordSubmit}>
                Submit
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Login;
