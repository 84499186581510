import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

// i18b
import { withNamespaces } from "react-i18next";

// Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import { post } from "../../../helpers/api_helper";

const NotificationDropdown = (props) => {
  const [menu, setMenu] = useState(false);
  const [notification, setNotification] = useState([]);
    let auth_info = JSON.parse(localStorage.getItem("auth_info"))
    
  const toggle = () => {
    setMenu(!menu);
  };

  const getAdminNotifications = async () => {
    let body = {
      isAdmin: true,
    };
    if(auth_info.isAdmin){
      body.isVendor = true
    }else if(auth_info.isAdmin === false){
      body.vendor_id = auth_info.id
    }
    else{
      body.isVendor = false;
    }
    let responce = await post("/api/get-admin-notifications",
      body
    );
    setNotification(responce);
  };
  useEffect(() => {
    if (menu === true) {
      getAdminNotifications();
    }
  }, [menu]);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle
          tag="button"
          className="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown"
        >
          <i className="ri-notification-3-line"></i>
          <span className="noti-dot"></span>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-end dropdown-menu-lg p-0"
          aria-labelledby="page-header-notifications-dropdown"
        >
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{props.t("Notifications")}</h6>
              </Col>
              {/* <div className="col-auto">
                <Link to="/notification-history" className="small">
                  {props.t("View All")}
                </Link>
              </div> */}
            </Row>
          </div>
          <SimpleBar style={{ maxHeight: "230px" }}>
            {notification.map((items, index) => (
              <Link
                to={
                  auth_info.isAdmin
                    ? items.pathRouteAdmin
                    : items.pathRouteVendor
                }
                className="text-reset notification-item"
                key={index}
              >
                <div className="d-flex">
                  <div className="flex-1">
                    <h6 className="mt-0 mb-1">
                      {items?.user
                        ? items?.user?.first_name + " " + items?.user?.last_name
                        : ""}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{items.message && items.message}</p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline"></i>{" "}
                        {items.createAt && items.createAt.split("T")[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </SimpleBar>
          {/* <div className="p-2 border-top">
            <Link
              to="/notification-history"
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              {props.t(" View More")}
            </Link>
          </div> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withNamespaces()(NotificationDropdown);
