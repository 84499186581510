import React, { useState } from "react";
import {
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  Button,
} from "reactstrap";
// import ProductImages from "./ProductImages";
// import Switch from "react-switch";
// import { Link } from "react-router-dom";
import { productVariantValidationCheck } from "../store/layout/actions";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import DisplayAttributesWithRespectiveValues from "./DisplayAttributesWithRespectiveValues";
import AddVariantProductModal from "./AddVariantProductModal";
import LinkWithText from "./LinkWithTitle";
import { del, get, post } from "../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
function VaraintProducts({
  setLoading,
  user,
  handleModalActive,
  product,
  setProduct,
  selectedProductForUpdate,
  loading,
  calculateShippingCost,
  handleVariantsSubmitClick,
}) {
  const navigation = useHistory();
  const dispatch = useDispatch();
  const [showCreateNewVariantModal, setShowCreateNewVariantModal] =
    useState(false);
  const variantValidationCheck = useSelector(
    (state) => state.Layout.VARIANT_VALIDATION_CHECK
  );

  const [selectedFiles, setSelectedFiles] = useState(
    selectedProductForUpdate?.row?.image_urls
      ? selectedProductForUpdate?.row?.image_urls.map((item) => ({
          name: item,
          preview: `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item}`,
          oldImage: true,
        }))
      : []
  );
  function handleShowCreateVariantModal() {
    setShowCreateNewVariantModal((prev) => !prev);
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!variantValidationCheck) {
        toastr.error("Please create product attribute and variant");

        return;
      }
      setLoading(true);
      handleVariantsSubmitClick();

      await post("/api/product/variant", {
        name: product.name,
        description: product.description,
        sub_category_id: product.sub_category_id,
        category_id: product.category_id,
        child_category_id: product?.child_category_id,
        key_features: product.key_features,
        materials: product.materials,
        assembly_instructions: product.assembly_instructions,
        additional_information: product.additional_information,
        is_shipping_free: product.is_shipping_free,
        id: product.id,
        vendor_id: user.id,
      });
      const res = await get(`/api/product/make-complete/${product.id}`);
      if (res.status) {
        // toastr.success("Product added!", "Success");
        dispatch(productVariantValidationCheck(false));
        navigation.push("/manage-products");
      }
      setLoading(false);
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      setLoading(false);
      console.error(e);
    }
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles((prev) => [...prev, ...files]);
  };
  return (
    <>
      <LinkWithText
        title={"Add New Attribute"}
        heading={"Attributes"}
        showLink={true}
        functionToCall={handleModalActive}
      />

      {product?.attributes?.length ? (
        <DisplayAttributesWithRespectiveValues
          attributesArr={product?.attributes}
          setProduct={setProduct}
          product={product}
          handleModalActive={handleModalActive}
        />
      ) : (
        <p>No attributes at the moment...</p>
      )}
      <LinkWithText
        title={"Add New Variant"}
        heading={"Variants"}
        showLink={product?.attributes?.length}
        functionToCall={handleShowCreateVariantModal}
      />
      {showCreateNewVariantModal && (
        <VariantProductModal
          showCreateNewVariantModal={showCreateNewVariantModal}
          handleShowCreateVariantModal={handleShowCreateVariantModal}
          setShowCreateNewVariantModal={setShowCreateNewVariantModal}
          parentProduct={product}
          setParentProduct={setProduct}
          setLoading={setLoading}
          user={user}
          loading={loading}
          calculateShippingCost={calculateShippingCost}
        />
      )}

      {product?.variants?.length ? (
        <>
          <Table>
            <tr>
              {product?.attributes.map((el) => {
                return <th>{el?.name}</th>;
              })}
              <th>Weight</th>
              <th>Selling Price</th>
              <th>MRP</th>
              <th>Length</th>
              <th>Width</th>
              <th>Height</th>
              <th>Color</th>
            </tr>

            {product?.variants?.length
              ? product?.variants?.map((item, index) => {
                  return (
                    <tr>
                      {item?.selectedAttributes?.map((element) => {
                        return <td>{element.value}</td>;
                      })}
                      <td>{item.weight}</td>
                      <td>{item.discountedPrice}</td>
                      <td>{item.MRP}</td>
                      <td>{item.length}</td>
                      <td>{item.width}</td>
                      <td>{item.height}</td>
                      <td>{item.color}</td>
                      <td>
                        <span
                          onClick={async () => {
                            const res = await del(
                              `/api/product/variant/${item?.id}`
                            );
                            if (!res.status) {
                              toastr.error(res.message);
                              return;
                            }
                            const variants = await get(
                              `/api/product/variants/${product?.id}`
                            );
                            setProduct((prev) => ({
                              ...prev,
                              variants: variants,
                            }));
                          }}
                        >
                          <i className="mdi mdi-delete font-size-18 "></i>
                        </span>
                      </td>
                      <td>
                        <span
                          onClick={async () => {
                            setProduct((prev) => ({
                              ...prev,
                              variantToBeEdited: item,
                            }));
                            handleShowCreateVariantModal();
                          }}
                        >
                          <i className="mdi mdi-pencil font-size-18 "></i>
                        </span>
                      </td>
                    </tr>
                  );
                })
              : null}
          </Table>
        </>
      ) : (
        <p>No variants at the moment...</p>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "30px auto",
        }}
      >
        <Button
          size="md"
          disabled={loading}
          onClick={handleSubmit}
          style={{
            backgroundColor: "#0077B6",
            color: "white",
          }}
        >
          {loading ? (
            <Spinner
              style={{ width: "2rem", height: "2rem" }}
              children={false}
            />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </>
  );
}

export default VaraintProducts;
function Offsymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      No
    </div>
  );
}

function OnSymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      Yes
    </div>
  );
}

function VariantProductModal({
  showCreateNewVariantModal,
  handleShowCreateVariantModal,
  setShowCreateNewVariantModal,
  parentProduct,
  setParentProduct,
  setLoading,
  user,
  loading,
  calculateShippingCost,
}) {
  const [isVariantAvailable, setIsVariabtAvailable] = useState(false);
  console.log("is variant available", isVariantAvailable);
  return (
    <Modal
      size="xl"
      isOpen={showCreateNewVariantModal}
      toggle={() => {
        setParentProduct((prev) => ({
          ...prev,
          variantToBeEdited: null,
        }));
        handleShowCreateVariantModal();
      }}
    >
      <ModalHeader
        toggle={() => {
          setParentProduct((prev) => ({
            ...prev,
            variantToBeEdited: null,
          }));
          handleShowCreateVariantModal();
        }}
      >
        Add New Variant
      </ModalHeader>
      <ModalBody>
        <AddVariantProductModal
          showCreateNewVariantModal={showCreateNewVariantModal}
          handleShowCreateVariantModal={handleShowCreateVariantModal}
          setShowCreateNewVariantModal={setShowCreateNewVariantModal}
          parentProduct={parentProduct}
          setParentProduct={setParentProduct}
          setLoading={setLoading}
          user={user}
          loading={loading}
          calculateShippingCost={calculateShippingCost}
          setIsVariabtAvailable={setIsVariabtAvailable}
        />
      </ModalBody>
    </Modal>
  );
}
