import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import toastr from "toastr";
import Switch from "react-switch";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import {
  Spinner,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import MasterForm from "../../components/VendorRegistration/MasterForm";

const PackageSizes = () => {
  const navigation = useHistory();
  const [dataObject, setDataObject] = useState({
    inch: false,
  });
  
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [ShipmentCharges, setShipmentCharges] = useState([]);
  const [documents, setDocuments] = useState([]);
  const dispatch = useDispatch();
  const [categoryFile, setCategoryFile] = useState(null);

  const isImageType = (file) => {
    const imageTypes = ["image/jpeg", "image/png"];
    return imageTypes.includes(file.type);
  };
  const handleModalShow = () => {
    setModalShow((prev) => !prev);
    setCategoryFile(null)
  };
  function getTotalFieldValue(info) {
    const local_delivery_charge = info?.local_delivery_charge
      ? info?.local_delivery_charge
      : 0;
    const international_courier_charge = info?.international_courier_charge
      ? info?.international_courier_charge
      : 0;
    const human_resource_charge = info?.human_resource_charge
      ? info?.human_resource_charge
      : 0;
    const taxes = info?.taxes ? info?.taxes : 0;
    let grandTotal = local_delivery_charge + international_courier_charge;
    if (info?.Order_charges_type === "ECOM") {
      grandTotal += human_resource_charge;
    }
    const taxAmount = (grandTotal * taxes) / 100;
    return taxAmount + grandTotal;
  }
  function calculateShipmentAmountTotal(shipType, orderType, value = 0) {
    const charges = ShipmentCharges.find(
      (el) =>
        el.shipping_charges_type === shipType &&
        el.Order_charges_type === orderType
    );
    const amountWithTax = getTotalFieldValue({
      ...charges,
      Order_charges_type: orderType,
    });
    let grandTotal = amountWithTax * value;
    if (dataObject?.discount) {
      const discountAmount = (grandTotal * dataObject?.discount) / 100;
      grandTotal = grandTotal - discountAmount;
    }
    return grandTotal;
  }
  async function handleNewDataObjectCreation() {
    try {
      const formData = new FormData();
      formData.append("icon_url", categoryFile);

      for (const property in dataObject) {
        if (dataObject.hasOwnProperty(property)) {
          formData.append(property, dataObject[property]);
        }
      }

      const config = {
        method: "POST",
        body: formData,
      };
      let responseObj = await fetch(
        `${process.env.REACT_APP_AUTHDOMAIN}/api/create-package-size`,
        config
      );
      const res = await responseObj.json();
      if (res?.error) {
        toastr.error(res?.error, "Error");
        setLoading(false);
        return;
      }
      setCategoryFile(null)
      handleModalShow();
      getAllCategories();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }
  const handleObjectChange = (event) => {
    const { name, value } = event.target;
    setDataObject((prev) => ({
      ...prev,
      [name]: name === "size_name" ? value : value,
    }));
  };
  useEffect(() => {
    getAllCategories();
  }, []);
  async function getAllCategories() {
    try {
      const content = await post("/api/get-package-size", {});
       const sortedContent = content?.packageSizes.sort(
         (a, b) => a.size_weight - b.size_weight
       );
      setOrders(sortedContent || []);
     
      setShipmentCharges(content?.shipmentCharges || []);
    } catch (e) {
      console.log(e);
    }
  }
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const { SearchBar } = Search;
  const handleCategoryFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && isImageType(selectedFile)) {
      setCategoryFile(
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile),
        })
      );
    } else {
      alert("Please select a valid image file (JPEG, PNG)");
    }
  };
  // Pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: orders.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "size_weight",
      order: "asc",
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "downloaded_image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
   function Offsymbol() {
     return (
       <div
         style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           height: "100%",
           fontSize: 12,
           color: "#fff",
           paddingRight: 2,
         }}
       >
         Inch
       </div>
     );
   }

   function OnSymbol() {
     return (
       <div
         style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           height: "100%",
           fontSize: 12,
           color: "#fff",
           paddingRight: 2,
         }}
       >
         Feet
       </div>
     );
   }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Package Sizes"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Package Sizes", link: "/package-sizes" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          setDataObject,
                          handleModalShow,
                          getAllCategories
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <a
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleModalShow();
                                      setDataObject({});
                                      setCategoryFile(null);
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Add New
                                    Package Size
                                  </a>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ minHeight: "200px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal size="xl" isOpen={modalShow} toggle={handleModalShow}>
        <ModalHeader toggle={handleModalShow}>Add New Package Size</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_name">
                  Size Name
                </Label>
                <Input
                  id="size_name"
                  name="size_name"
                  type="text"
                  value={dataObject?.size_name}
                  onChange={handleObjectChange}
                  className="form-control"
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_length">
                  Size Length
                </Label>
                <Input
                  id="size_length"
                  name="size_length"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  type="text"
                  value={dataObject?.size_length
                    ?.toString()
                    ?.replace(/[^0-9.]/g, "")}
                  onChange={handleObjectChange}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_width">
                  Size width
                </Label>
                <Input
                  id="size_width"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  name="size_width"
                  type="text"
                  value={dataObject?.size_width
                    ?.toString()
                    ?.replace(/[^0-9.]/g, "")}
                  onChange={handleObjectChange}
                  className="form-control"
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_height">
                  Size Height
                </Label>
                <Input
                  id="size_height"
                  name="size_height"
                  type="text"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  value={dataObject?.size_height
                    ?.toString()
                    ?.replace(/[^0-9.]/g, "")}
                  onChange={handleObjectChange}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_weight">
                  Size Weight (KG)
                </Label>
                <Input
                  id="size_weight"
                  name="size_weight"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  type="text"
                  value={dataObject?.size_weight
                    ?.toString()
                    ?.replace(/[^0-9.]/g, "")}
                  onChange={handleObjectChange}
                  className="form-control"
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="discount">
                  Discount (%)
                </Label>
                <Input
                  id="discount"
                  name="discount"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  type="number"
                  value={dataObject?.discount
                    ?.toString()
                    ?.replace(/[^0-9.]/g, "")}
                  onChange={handleObjectChange}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="shipment_ship">
                  Shipment Ship
                </Label>
                <Input
                  id="shipment_ship"
                  name="shipment_ship"
                  type="text"
                  value={calculateShipmentAmountTotal(
                    "SHIP",
                    "SHIPMENT",
                    dataObject?.size_weight
                  )}
                  disabled={true}
                  className="form-control"
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="shipment_air">
                  Shipment Air
                </Label>
                <Input
                  id="shipment_air"
                  name="shipment_air"
                  type="text"
                  value={calculateShipmentAmountTotal(
                    "AIR",
                    "SHIPMENT",
                    dataObject?.size_weight
                  )}
                  disabled={true}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="ecom_ship">
                  Ecommerce Ship
                </Label>
                <Input
                  id="ecom_ship"
                  name="ecom_ship"
                  type="text"
                  value={calculateShipmentAmountTotal(
                    "SHIP",
                    "ECOM",
                    dataObject?.size_weight
                  )}
                  disabled={true}
                  className="form-control"
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="ecom_air">
                  Ecommerce Air
                </Label>
                <Input
                  id="ecom_air"
                  name="ecom_air"
                  type="text"
                  value={calculateShipmentAmountTotal(
                    "AIR",
                    "ECOM",
                    dataObject?.size_weight
                  )}
                  disabled={true}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label for="pan">Package Box Icon</Label>
                <FormGroup>
                  <Input
                    type="file"
                    name="file"
                    id="category"
                    onChange={handleCategoryFileChange}
                  />
                  <img
                    src={
                      categoryFile?.preview
                        ? categoryFile?.preview
                        : dataObject?.icon_url
                        ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${dataObject?.icon_url}`
                        : ``
                    }
                    width="150px"
                  />
                </FormGroup>
              </div>
            </Col>
            <Col md="6">
              <Label for="percenttrue">In Inch or Feet</Label>
              <FormGroup>
                <Switch
                  id="percenttrue"
                  checkedIcon={<OnSymbol />}
                  uncheckedIcon={<Offsymbol />}
                  onColor="#626ed4"
                  onChange={(checked) => {
                    setDataObject({
                      ...dataObject,
                      inch: checked,
                    });
                  }}
                  checked={dataObject?.inch || false}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                ) : (
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light"
                    type="submit"
                    onClick={handleNewDataObjectCreation}
                  >
                    Save
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default PackageSizes;
