import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import toastr from "toastr";
import Editor from "react-simple-wysiwyg";
import { Dropdown, DropdownItem, DropdownMenu, Button } from "reactstrap";
import {
  ModalFooter,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  DropdownToggle,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post } from "../../helpers/api_helper";
import { useDispatch } from "react-redux";
import { changeActiveEcommerceTab } from "../../store/actions";

const AdminEcommerceOrder = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showDifferenceModal, setShowDifferenceModal] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [differenceAmount, setDifferenceAmount] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [activeTab, setActiveTab] = useState("PLACED");
  const [selectedOrderForEmail, setSelectedOrderForEmail] = useState(null);
  const [email, setEmail] = useState("");
  const [reRenderState, setRerenderState] = useState(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [newOrderStatus, setNewOrderStatus] = useState("");
  const [documents, setDocuments] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState({
    title: "",
    subject: "",
    message: "",
  });
  const user = useSelector((state) => state.userAuthInfo.user);
  const handleShowDifferenceModal = () => {
    setShowDifferenceModal((prev) => !prev);
  };
  const toggleModal = () => {
    setDisplayModal((prev) => !prev);
    setEmailTemplate({
      title: "",
      subject: "",
      message: "",
    });
    setSelectedOrderForEmail(null);
  };

  async function createDifferencePaymentLink() {
    try {
      if (!differenceAmount) {
        toastr.error("Please provide difference amount");
        return;
      }
      if (!remarks) {
        toastr.error("Please provide remarks");
        return;
      }
      await post("/api/create-custom-difference-payment-link-ecommerce", {
        difference_remarks: remarks,
        difference_amount: differenceAmount,
        shipment_order_id: documents.id,
      });
      toastr.success("Success sent payment link");
      handleShowDifferenceModal();
      setRerenderState(!reRenderState);
      setRemarks("");
    } catch (e) {
      console.log(e);
      toastr.error("Error creating payment link");
    }
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setSelectedOrderIds([]);
      dispatch(changeActiveEcommerceTab(tab));
    }
  };

  useEffect(() => {
    getAllVendorRequests();
  }, [activeTab, user, reRenderState]);

  async function getAllVendorRequests(status) {
    try {
      const content = await post("/api/ecom/get-all-orders/admin", {
        status: status ? status : activeTab,
      });
      setOrders(content);
    } catch (e) {
      console.log(e);
    }
  }

  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: orders?.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

const selectRow = {
  mode: "checkbox",
  selected: selectedOrderIds, 
  onSelect: (row, isSelected) => {
    if (isSelected) {
      setSelectedOrderIds((prevIds) => [...prevIds, row.id]);
    } else {
      setSelectedOrderIds((prevIds) => prevIds.filter((id) => id !== row.id));
    }
  },
  onSelectAll: (isSelected, rows) => {
    if (isSelected) {
      setSelectedOrderIds(rows.map((row) => row.id));
    } else {
      setSelectedOrderIds([]);
    }
  },
};


  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleBulkStatusUpdateClick = async () => {
    toggleDropdown();
  };

  async function sendEmailToCustomer() {
    try {
      setLoading(true);
      await post("/api/send-email-to-customer", {
        ...emailTemplate,
        email: email?.user?.email,
      });
      toastr.success("Email sent successfully!");
      toggleModal();
    } catch (e) {
      console.log(e);

      toastr.error(
        e?.response?.data?.message || "Error sending email",
        "Error"
      );
    } finally {
      setLoading(false);
    }
  }
  const orderStatus = [
    "READY_TO_DISPATCH",
    "PROCESSING",
    "RECEIVED",
    "SHIPPED",
    "REACHED_TO_LOCAL_PARTNER",
    "DELIVERED",
  ];

  const statusIcons = {
    READY_TO_DISPATCH: "mdi mdi-truck-delivery",
    PROCESSING: "mdi mdi-cog-outline",
    RECEIVED: "mdi mdi-package-variant",
    SHIPPED: "mdi mdi-truck",
    REACHED_TO_LOCAL_PARTNER: "mdi mdi-map-marker-check",
    DELIVERED: "mdi mdi-check-all",
  };

  const currentStatusIndex = orderStatus.indexOf(activeTab);
  const availableStatuses = orderStatus.slice(currentStatusIndex + 1);

  const handleItemClick = async (item) => {
    setDropdownOpen(false);
    setNewOrderStatus(item);
    let body = {
      orderIds: selectedOrderIds,
      status: item,
    };
    
    if (newOrderStatus !== "") {
      let response = await post("/api/update-ecom-orderstatus", body);

      setSelectedOrderIds([])
      toastr.success(response.message);
      getAllVendorRequests();
    }
  };
  const [orderStatusCount,setOrderStatusCount] = useState()
  const marketplaceOrderCount = async()=>{
    let responce = await post("/api/marketplace-orders-steps-count");
    setOrderStatusCount(responce);
    
  }
  useEffect(()=>{
    marketplaceOrderCount();
  },[])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Marketplace Orders"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Marketplace Orders", link: "#" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "PLACED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("PLACED")}
                              >
                                {`PLACED (${orderStatusCount?.Placed})`}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "READY_TO_DISPATCH"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => toggleTab("READY_TO_DISPATCH")}
                              >
                                {`READY TO DISPATCH (${orderStatusCount?.readt_to_dispatch})`}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "PROCESSING" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("PROCESSING")}
                              >
                                {`PROCESSING (${orderStatusCount?.processing})`}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "RECEIVED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("RECEIVED")}
                              >
                                {`RECEIVED (${orderStatusCount?.received})`}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "SHIPPED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("SHIPPED")}
                              >
                                {`SHIPPED (${orderStatusCount?.shipped})`}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "REACHED_TO_LOCAL_PARTNER"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  toggleTab("REACHED_TO_LOCAL_PARTNER")
                                }
                              >
                                {`REACHED TO LOCAL PARTNER (${orderStatusCount?.Reached_to_local_partner})`}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "DELIVERED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("DELIVERED")}
                              >
                                {`DELIVERED (${orderStatusCount?.delivered})`}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "HOLD" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("HOLD")}
                              >
                                {`HOLD (${orderStatusCount?.hold})`}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "CANCELLED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("CANCELLED")}
                              >
                                {`CANCELLED (${orderStatusCount?.cancelled})`}
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          getAllVendorRequests,
                          setDocuments,
                          handleShowDifferenceModal,
                          setSelectedOrderForEmail,
                          toggleModal,
                          setEmail,
                          orders?.length
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <div style={{ display: "flex", gap: "20px" }}>
                              <SearchBar {...toolkitProps.searchProps} />
                              {selectedOrderIds.length > 0 &&
                                activeTab !== "DELIVERED" && (
                                  <>
                                    <Dropdown
                                      isOpen={dropdownOpen}
                                      direction="down"
                                      toggle={toggleDropdown}
                                      color="#a8d1df"
                                    >
                                      <DropdownToggle
                                        color="primary"
                                        className="primary"
                                        caret
                                        onClick={handleBulkStatusUpdateClick}
                                      >
                                        Update status
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {availableStatuses.map((status) => (
                                          <DropdownItem
                                            key={status}
                                            onClick={() =>
                                              handleItemClick(status)
                                            }
                                          >
                                            <i
                                              className={`${statusIcons[status]} font-size-18 me-2`}
                                            ></i>
                                            {status.replace(/_/g, " ")}
                                          </DropdownItem>
                                        ))}
                                      </DropdownMenu>
                                    </Dropdown>
                                  </>
                                )}
                            </div>

                            <BootstrapTable
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={false}
                              selectRow={selectRow}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              defaultSorted={defaultSorted}
                            />
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Difference Modal */}
      <Modal
        size="ml"
        isOpen={showDifferenceModal}
        toggle={handleShowDifferenceModal}
      >
        <ModalHeader toggle={handleShowDifferenceModal}>
          Manage Difference
        </ModalHeader>
        <ModalBody>
          <Label>Difference Amount</Label>
          <Input
            type="number"
            value={differenceAmount}
            onChange={(e) => setDifferenceAmount(e.target.value)}
          />
          <Label className="mt-3">Remarks</Label>
          <Input
            type="text"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleShowDifferenceModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={createDifferencePaymentLink}>
            Create Payment Link
          </Button>
        </ModalFooter>
      </Modal>
      {/* Email Modal */}
      <Modal isOpen={displayModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Send Email To Customer</ModalHeader>
        <ModalBody>
          <Label>Title</Label>
          <Input
            type="text"
            value={emailTemplate.title}
            onChange={(e) =>
              setEmailTemplate({ ...emailTemplate, title: e.target.value })
            }
          />
          <Label className="mt-3">Subject</Label>
          <Input
            type="text"
            value={emailTemplate.subject}
            onChange={(e) =>
              setEmailTemplate({ ...emailTemplate, subject: e.target.value })
            }
          />
          <Label className="mt-3">Message</Label>
          <textarea
            value={emailTemplate.message}
            onChange={(e) => {
              setEmailTemplate({ ...emailTemplate, message: e.target.value });
            }}
            rows="5"
            className="form-control"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
          <Button onClick={sendEmailToCustomer} color="primary">
            {loading ? `Loading..` : `Send`}{" "}
            <i className="fab fa-telegram-plane ms-1"></i>
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default AdminEcommerceOrder;
