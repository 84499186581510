import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import toastr from "toastr";
import Editor from "react-simple-wysiwyg";
import {
  Spinner,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
} from "reactstrap";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";

const NotificationBar = () => {
  const navigation = useHistory();
  const dispatch = useDispatch();
  const [notificationObject, setNotificationObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.userAuthInfo.user);
  const [notification, setNotification] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: notification.length,
    custom: true,
  };
  const { SearchBar } = Search;
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  function showNewModal() {
    setShowModal((prev) => !prev);
    setNotificationObject({});
  }

  // const selectRow = {
  //   mode: "checkbox",
  // };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setNotificationObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  async function getAllNotifications(isAdmin) {
    let body = {
      isAdmin :isAdmin
    }
    const notificationInfo = await post("/api/get-admin-notifications", 
      body
    );
    setNotification(notificationInfo);
  }


  useEffect(() => {
    getAllNotifications(true);
  }, [user]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Notification History"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Notification History", link: "/#" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={notification}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={notification}
                        columns={EcommerceOrderColumns(
                          dispatch,
                          navigation,
                          user
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <a
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showNewModal();
                                    }}
                                  >
                                    {/* <i className="mdi mdi-plus me-1" /> Create New Notification */}
                                  </a>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ minHeight: "200px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal size="xl" isOpen={showModal} toggle={showNewModal}>
        {/* <ModalHeader toggle={showNewModal}>Create New Notification</ModalHeader> */}
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="title">
                  {" "}
                  Title{" "}
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  value={notificationObject?.title}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <label className="form-label" htmlFor="productdesc">
              {" "}
              Description{" "}
            </label>
            <Editor
              containerProps={{ style: { height: "200px" } }}
              value={notificationObject?.description}
              onChange={(e) => {
                setNotificationObject((prev) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
            />
          </div>
          <Row>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                ) : (
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light"
                    type="submit"
                    // onClick={createNewnotification}
                  >
                    Save{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default NotificationBar;
