import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import toastr from "toastr";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  FormGroup,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { del, get, post } from "../../helpers/api_helper";
import SearchableDropdown from "../../components/SearchableDropdown";

const AddLogoVendorStore = () => {
  const navigation = useHistory();
  const [imageFile, setImageFile] = useState(null);
  let vendorData = JSON.parse(localStorage.getItem("auth_info"));
  async function savePage() {
    try {
      if (!imageFile) {
        toastr.error("Please select an image");
        return;
      }

      const formData = new FormData();

      formData.append("logo", imageFile);
      formData.append("vendor_id", vendorData?.id);
      const config = {
        method: "POST",
        body: formData,
      };

      // Send the FormData to the server
      const responseObj = await fetch(
        `${process.env.REACT_APP_AUTHDOMAIN}/api/create-vendor-store-logo`,
        config
      );
      const res = await responseObj.json();

      navigation.push("/store-management");
    } catch (e) {
      console.log(e);
    }
  }
  const handleImageFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && isImageType(selectedFile)) {
      setImageFile(
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile),
        })
      );
    } else {
      alert("Please select a valid image file (JPEG, PNG)");
    }
  };
  const isImageType = (file) => {
    const imageTypes = ["image/jpeg", "image/png"];
    return imageTypes.includes(file.type);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Add New Page Web"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Add New Page Web", link: "#" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="mb-3">
                  <Label>{`Logo`}</Label>
                  <FormGroup>
                    <Input
                      type="file"
                      name="file"
                      id="category"
                      className="mb-3"
                      onChange={handleImageFileChange}
                    />
                    <img src={imageFile?.preview} width="150px" />
                  </FormGroup>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{ marginTop: "30px" }}
                      color="primary"
                      className="primary"
                      onClick={savePage}
                    >
                      Save
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddLogoVendorStore;
