import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UmbrellaApiCallState } from "../../store/actions";
import {
  Badge,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import toastr from "toastr";
const EcommerceOrderColumns = (
  toggleModal,
  setSelectedUmbrella,
  shipmentType,
  setContainerId,
  setIsUmbrellaStatusUpdate
) => {
  return [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "identifier_id",
      text: "Container Identifier",
      sort: true,
      formatter: (cellContent, row) => (
        <Link
          to="#"
          className="text-body fw-bold"
          onClick={() => {
            setContainerId(row.id);
          }}
        >
          {row.identifier_id}
        </Link>
      ),
    },
    {
      dataField: "shipment_type",
      text: "Shipment Type",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.shipment_type}
        </Link>
      ),
    },
    {
      dataField: "no_of_orders",
      text: "No. of Orders",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row?._count
            ? row._count.ecommerce_ordered_Items + row._count.shipment_orders
            : "-"}
        </Link>
      ),
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {new Date(row?.createdAt)?.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }) || ""}
        </Link>
      ),
    },
    {
      dataField: "expected_date",
      text: "Expected Date",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {new Date(row?.expected_date)?.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }) || ""}
        </Link>
      ),
    },
    shipmentType !== "REACHED TO LOCAL PARTNER" && {
      dataField: "remaining_days",
      text: "Remaining Days",
      sort: true,
      formatter: (cellContent, row) => {
        const expectedDate = new Date(row?.expected_date);
        const currentDate = new Date();
        const remainingDays = Math.ceil(
          (expectedDate - currentDate) / (1000 * 60 * 60 * 24)
        );
        return (
          <Link to="#" className="text-body fw-bold">
            {remainingDays >= 0 ? `${remainingDays} days` : "Expired"}
          </Link>
        );
      },
    },
    {
      dataField: "action_send_email",
      isDummyField: true,
      text: "Send Email",
      formatter: (cellContent, row) => (
        <Button
          color="primary"
          onClick={() => {
            toggleModal();
            setSelectedUmbrella(row?.id);
            
          }}
        >
          Send Email
        </Button>
      ),
    },
    shipmentType !== "REACHED TO LOCAL PARTNER" && {
      dataField: "change_status",
      text: "Change Status",
      sort: true,
      formatter: (cellContent, row) => {
        return <StatusDropdown row={row} apiState={setIsUmbrellaStatusUpdate} />;
      },
    },
  ].filter(Boolean);
};
const StatusDropdown = ({ row, apiState }) => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [umbrellaApiState, setUmbrellaApiState] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const statuses = ["REACHED_TO_LOCAL_PARTNER"];
  const handleStatusChange = async (newStatus) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTHDOMAIN}/api/change-status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            containerId: row.id,
            orderStatus: newStatus,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Something went wrong");
      }
      setUmbrellaApiState(!umbrellaApiState);
      toastr.success("status update successfully");
      apiState(true);
      dispatch(UmbrellaApiCallState(umbrellaApiState));
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="d-inline-block"
    >
      <DropdownToggle
        color="secondary"
        className="primary"
        caret
        style={{ backgroundColor: "#00B4D8" }}
      >
        <i className="mdi mdi-menu font-size-18"></i>
      </DropdownToggle>
      <DropdownMenu>
        {statuses.map((status) => (
          <DropdownItem key={status} onClick={() => handleStatusChange(status)}>
            <i className={`mdi mdi-city font-size-18 me-2`}></i>
            {status}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default EcommerceOrderColumns;