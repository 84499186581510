import React from "react";
import { Link } from "react-router-dom";
const EcommerceOrderColumns = () => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "date",
    text: "Date",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.createAt?.split("T")[0]}
      </Link>
    ),
  },
  {
    dataField: "title",
    text: "Title",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.title}
      </Link>
    ),
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row?.description ? `${row?.description?.substring(0, 60)}...` : "-"}
      </Link>
    ),
  },
];

export default EcommerceOrderColumns;
