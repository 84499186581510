import React from "react";
import { Container, Row, Col, CardBody, Card, Table,Button } from "reactstrap";
import DatePicker from "react-datepicker"; 
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MiniWidgets from "./MiniWidgets";
import { get, post } from "../../helpers/api_helper";
import { useSelector } from "react-redux";
import { supportData } from "../../store/layout/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { customerSupportData } from "../../store/layout/actions";

const AdminShipmentDashboard = () => {
  const dispatch = useDispatch();
  const navigation = useHistory();
  const [data, setData] = React.useState({data:[]});
  const [selectedDate, setSelectedDate] = React.useState(null);

  const clearDate = () => {
    setSelectedDate(null);
  };
  
  const breadcrumbItems = [
    { title: "Shipora", link: "/" },
    { title: "Dashboard", link: "#" },
  ];

  const user = useSelector((state) => state.userAuthInfo.user);
  const formattedDate = selectedDate
    ? new Date(selectedDate.getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        // .split("T")[0]
    : null;
  React.useEffect(() => {
    getDashboardInfo();
  }, [user,formattedDate]);
  async function getDashboardInfo() {
    try {
      const data = await post(`/api/admin/shipment/dashbord-info`,{
        formattedDate
      });
      setData(data);
    } catch (e) {
      console.log(e);
    }
  }
  const handleViewDetailClick = (item) =>{
    dispatch(customerSupportData(item));
    navigation.push("/support-customer");
    
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col></Col>
            <Col md={2} className="text-right mb-3">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholderText="Select Date"
              />
            </Col>
            {selectedDate != null && (
              <Col sm="1" className="d-inline-block">
                <button onClick={clearDate} className="btn btn-primary">
                  Clear
                </button>
              </Col>
            )}
          </Row>
          <Row>
            <Col xl={12}>
              <Row>
                <MiniWidgets reports={data?.data} />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <div className=" overflow-hidden">
                      <h4 className="mb-2">Containers</h4>
                    </div>
                    <Table>
                      <tr>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          Container Name
                        </th>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          Shipment Type
                        </th>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          Order Status
                        </th>
                        <th>Expected Date</th>
                      </tr>
                      {data?.umbrella_management?.map((item) => {
                        return (
                          <tr>
                            <td>{item?.identifier_id}</td>
                            <td>{item?.shipment_type}</td>
                            <td>{item?.order_status}</td>
                            <td>{item.expected_date.split("T")[0]}</td>
                          </tr>
                        );
                      })}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <div className=" overflow-hidden">
                      <h4 className="mb-2">Support</h4>
                    </div>
                    <Table>
                      <tr>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          FullName
                        </th>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          Module
                        </th>
                        <th style={{ fontWeight: 700, fontSize: "15px" }}>
                          Subject
                        </th>
                        <th>Type Of Query</th>
                        <th>View Details</th>
                      </tr>
                      {data?.support?.map((item) => {
                        return (
                          <tr>
                            <td>{item?.fullName}</td>
                            <td>{item?.module}</td>
                            <td>{item?.subject}</td>
                            <td>{item?.type_of_query}</td>
                            <td>
                              <Button
                                style={{
                                  backgroundColor: "#00b4d8",
                                  border: "none",
                                  padding : 5
                                }}
                                onClick={()=>handleViewDetailClick(item)}
                              >
                                View Detail
                              </Button>
                            </td>
                          </tr>
                        );
                        })}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminShipmentDashboard;
