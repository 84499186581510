import React from "react";
import { Link } from "react-router-dom";
const EcommerceOrderColumns = () => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "date",
    text: "Date",
    sort: true,
    formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.createAt.split("T")[0]}
        </Link>
    ),
  },
  {
    dataField: "email",
    text: "email",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.user.email}
      </Link>
    ),
  },
  {
    dataField: "user_name",
    text: "UserName",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.user.first_name + " " + row.user.last_name}
      </Link>
    ),
  },
  {
    dataField: "Message",
    text: "Message",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.message}
      </Link>
    ),
  },
];

export default EcommerceOrderColumns;
