import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import Switch from "react-switch";
import ProductBasicDetails from "../../components/ProductBasicDetails";
import SoloProduct from "../../components/SoloProduct";
import VaraintProducts from "../../components/VariantProduct";

const AddProduct = () => {
  const [modalActive, setModalActive] = React.useState(false);
  const selectedProductForUpdate = useSelector(
    (state) => state.userAuthInfo.selectedProductForUpdate
  );
  const user = useSelector((state) => state.userAuthInfo.user);
  const [selectedFiles, setSelectedFiles] = useState(
    selectedProductForUpdate?.row?.image_urls
      ? selectedProductForUpdate?.row?.image_urls.map((item) => ({
          name: item,
          preview: `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item}`,
          oldImage: true,
        }))
      : []
  );
  const [activeTab, setActiveTab] = useState(1);
  const [shippingCharges, setShippingCharges] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [childCategory, setChildCategories] = useState([])
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({
    name: selectedProductForUpdate?.row?.name || "",
    id: selectedProductForUpdate?.row?.id || "",
    description: selectedProductForUpdate?.row?.description || "",
    discountedPrice: selectedProductForUpdate?.row?.discountedPrice || "",
    MRP: selectedProductForUpdate?.row?.MRP || "",
    stock: selectedProductForUpdate?.row?.stock || "",
    slug: selectedProductForUpdate?.row?.slug || "",
    weight: selectedProductForUpdate?.row?.weight || "",
    height: selectedProductForUpdate?.row?.height || "",
    length: selectedProductForUpdate?.row?.length || "",
    width: selectedProductForUpdate?.row?.width || "",
    brand_name: selectedProductForUpdate?.row?.brand_name || "",
    manufacturer_name: selectedProductForUpdate?.row?.manufacturer_name || "",
    manufacturer_brand: selectedProductForUpdate?.row?.manufacturer_brand || "",
    meta_title: selectedProductForUpdate?.row?.meta_title || "",
    meta_description: selectedProductForUpdate?.row?.meta_description || "",
    category_id: selectedProductForUpdate?.row?.category_id || "",
    sub_category_id: selectedProductForUpdate?.row?.sub_category_id || "",
    child_category_id : selectedProductForUpdate?.row?.child_category_id || "",
    attributes: selectedProductForUpdate?.row?.attributes || [],
    variants: selectedProductForUpdate?.row?.variants || [],
    is_shipping_free: selectedProductForUpdate?.row?.is_shipping_free || false,
    inStock : selectedProductForUpdate?.row?.inStock || false,
    additional_information: selectedProductForUpdate?.row?.additional_information || "",
    contain_variants: selectedProductForUpdate?.row?.contain_variants || false,
    key_features: selectedProductForUpdate?.row?.key_features || "",
    materials: selectedProductForUpdate?.row?.materials || "",
    assembly_instructions: selectedProductForUpdate?.row?.assembly_instructions || "",
  });
  
  function handleModalActive() {
    setModalActive((prev) => !prev);
  }
  async function getShippingCharges() {
    try {
      const content = await get("/api/shipping-charges", {});
      setShippingCharges(content);
    } catch (e) {
      console.log(e);
    }
  }
  const navigation = useHistory();
  const dispatch = useDispatch();
  const handleChange = (event, bool) => {
    const { name, value } = event.target;
    setProduct((prev) => ({
      ...prev,
      [name]: bool ? value?.replace(/[^0-9.]/g, "") : value,
    }));
  };
  useEffect(() => {
    getCategories();
    getShippingCharges();
  }, []);
  const breadcrumbItems = [
    { title: "Ecommerce", link: "#" },
    { title: "Add Product", link: "#" },
  ];

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  async function getCategories() {
    try {
      const content = await post("/api/ecom/fetch-categories", {});
      if (content.categories) {
        const categories = content.categories.map((el) => ({
          label: el.name,
          value: el.id,
        }));
        setCategory(categories || []);
      }
    } catch (e) {
      console.log(e);
    }
  }
  function calculateShippingCost(weight, type) {
    if (type === "AIR") {
      const charges = shippingCharges.find(
        (el) =>
          el.shipping_charges_type === type &&
          el.Order_charges_type === "SHIPMENT"
      );
      if (charges && weight) {
        let calculatedAmount =
          weight *
          (charges.international_courier_charge +
            charges.local_delivery_charge);
        return calculatedAmount;
      } else {
        return 0;
      }
    } else {
      const charges = shippingCharges.find(
        (el) =>
          el.shipping_charges_type === "SHIP" &&
          el.Order_charges_type === "SHIPMENT"
      );
      if (charges && weight) {
        let calculatedAmount =
          weight *
          (charges.international_courier_charge +
            charges.local_delivery_charge);
        return calculatedAmount;
      } else {
        return 0;
      }
    }
  }
  async function getSubCategoriesFromCategory(parentId) {
    try {
      const content = await post("/api/ecom/fetch-subcategories", {
        category_id: parentId,
      });
      if (content.length) {
        const categories = content.map((el) => ({
          label: el.name,
          value: el.id,
        }));
        setSubCategory(categories || []);
      }
    } catch (e) {
      console.log(e);
    }
  }
   async function getChildCategoriesFromChildCategory(childId) {
     try {
       const content = await post("/api/ecom/fetch-childcategories", {
         sub_category_id: childId,
       });
       if (content.length) {
         const childCategories = content.map((el) => ({
           label: el.name,
           value: el.id,
         }));
         setChildCategories(childCategories || []);
       }
     } catch (e) {
       console.log(e);
     }
   }

  React.useEffect(() => {
    if (product.category_id) {
      getSubCategoriesFromCategory(product.category_id);
    }
  }, [product.category_id]);
 React.useEffect(() => {
   if (product.sub_category_id) {
     getChildCategoriesFromChildCategory(product.sub_category_id);
   }
 }, [product.sub_category_id]);
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const [isVariantBtnClick,setIsVariantBtnClick] = useState(false)
  
  const handleVariantsSubmitClick = () =>{
    setIsVariantBtnClick(true)
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Add Product" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div
                    id="addproduct-nav-pills-wizard"
                    className="twitter-bs-wizard"
                  >
                    <CardTitle className="h5">Product Information</CardTitle>
                    <p className="card-title-desc">
                      Fill all information below
                    </p>
                    <ProductBasicDetails
                      categories={category}
                      subCategory={subCategory}
                      childCategory={childCategory}
                      setProduct={setProduct}
                      product={product}
                      handleChange={handleChange}
                    />
                  </div>
                  {/* <Row className="my-2">
                    <Col md="12">
                      <Label for="percenttrue">
                        Free Shipping For This Product?
                      </Label>
                      <FormGroup>
                        <Switch
                          id="percenttrue"
                          uncheckedIcon={<Offsymbol />}
                          className="me-1 mb-sm-8 mb-2 "
                          checkedIcon={<OnSymbol />}
                          onColor="#626ed4"
                          onChange={() =>
                            setProduct((prev) => ({
                              ...prev,
                              is_shipping_free: !product.is_shipping_free,
                            }))
                          }
                          checked={product.is_shipping_free}
                        />
                      </FormGroup>
                      <Label className="form-label">
                        <strong>NOTE:</strong> if shipping is free, shipping fee
                        will be deducted from vendor revenue amount.
                      </Label>
                    </Col>
                  </Row> */}
                  <Row className="my-2">
                    <Col md="12">
                      <Label for="inStock">Product Available in Stock</Label>
                      <FormGroup>
                        <Switch
                          id="inStock"
                          uncheckedIcon={<Offsymbol />}
                          className="me-1 mb-sm-8 mb-2 "
                          checkedIcon={<OnSymbol />}
                          onColor="#626ed4"
                          onChange={() =>
                            setProduct((prev) => ({
                              ...prev,
                              inStock: !product.inStock,
                            }))
                          }
                          checked={product.inStock}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col md="12">
                      <Label for="percenttrue">
                        Does this product contains variants?
                      </Label>
                      <FormGroup>
                        <Switch
                          id="percenttrue"
                          uncheckedIcon={<Offsymbol />}
                          className="me-1 mb-sm-8 mb-2 "
                          checkedIcon={<OnSymbol />}
                          onColor="#626ed4"
                          onChange={async () => {
                            try {
                              if (
                                !product.contain_variants &&
                                (product.name === "" ||
                                  product.description === "" ||
                                  !product.category_id ||
                                  // !product.brand_name ||
                                  !product.sub_category_id)
                              ) {
                                toastr.error(
                                  "Please complete product above mentioned basic details in order to continue"
                                );
                                return;
                              }
                              if (!product.contain_variants) {
                                const productInfo = await post(
                                  "/api/product/variant",
                                  {
                                    name: product.name,
                                    vendor_id: user.id,
                                    description: product.description,
                                    sub_category_id: product.sub_category_id,
                                    // brand_name: product.brand_name,
                                    category_id: product.category_id,
                                    child_category_id:
                                      product.child_category_id,
                                    is_shipping_free: product.is_shipping_free,
                                  }
                                );
                                setProduct((prev) => ({
                                  ...prev,
                                  contain_variants: !product.contain_variants,
                                  id: productInfo?.id,
                                }));
                              } else {
                                setProduct((prev) => ({
                                  ...prev,
                                  contain_variants: !product.contain_variants,
                                }));
                              }
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                          checked={product.contain_variants}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* {!product?.contain_variants ? ( */}
                  {/* <> */}
                  <SoloProduct
                    loading={loading}
                    setLoading={setLoading}
                    user={user}
                    selectedProductForUpdate={selectedProductForUpdate}
                    setProduct={setProduct}
                    product={product}
                    iscontentVariant={product.contain_variants}
                    handleChange={handleChange}
                    shippingCharges={shippingCharges}
                    calculateShippingCost={calculateShippingCost}
                    isProductSubmitClick
                    isVariantBtnClick={isVariantBtnClick}
                  />
                  {/* </> */}
                  {/* ) : ( */}
                  {product?.contain_variants === true && (
                    <VaraintProducts
                      loading={loading}
                      setLoading={setLoading}
                      user={user}
                      selectedProductForUpdate={selectedProductForUpdate}
                      setProduct={setProduct}
                      product={product}
                      handleChange={handleChange}
                      handleModalActive={handleModalActive}
                      shippingCharges={shippingCharges}
                      calculateShippingCost={calculateShippingCost}
                      handleVariantsSubmitClick={handleVariantsSubmitClick}
                    />
                  )}
                  {/* )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="xl"
        isOpen={modalActive}
        toggle={() => {
          handleModalActive();
          setProduct((prev) => ({
            ...prev,
            tempAttributeName: "",
            tempAttributeValueName: "",
            tempAttributeId: null,
          }));
        }}
      >
        <ModalHeader
          toggle={() => {
            handleModalActive();
            setProduct((prev) => ({
              ...prev,
              tempAttributeName: "",
              tempAttributeValueName: "",
              tempAttributeId: null,
            }));
          }}
        >
          Add New Attribute
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="attributeName">
                  Attribute Name
                </Label>
                <Input
                  id="attributeName"
                  name="attributeName"
                  value={product.tempAttributeName}
                  onChange={(e) => {
                    setProduct((prev) => ({
                      ...prev,
                      tempAttributeName: e.target.value,
                    }));
                  }}
                  type="text"
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="attributeName">
                  Attribute Values (Separate with | symbol)
                </Label>
                <Input
                  id="attributeName"
                  name="attributeName"
                  value={product.tempAttributeValueName}
                  onChange={(e) => {
                    setProduct((prev) => ({
                      ...prev,
                      tempAttributeValueName: e.target.value,
                    }));
                  }}
                  type="text"
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <Button
            size="md"
            onClick={async () => {
              if (!product.tempAttributeName) {
                toastr.error("Attribute name is required");
                return;
              }
              if (!product.tempAttributeValueName) {
                toastr.error("Attribute values are required");
                return;
              }
              await post("/api/product/create-attribute", {
                name: product.tempAttributeName,
                values: JSON.stringify(product.tempAttributeValueName),
                product_id: product.id,
                ...(product?.tempAttributeId && {
                  id: product.tempAttributeId,
                }),
              });
              const attributes = await get(
                `/api/product/attribute/${product.id}`
              );
              setProduct((prev) => ({
                ...prev,
                attributes: attributes,
              }));
              setProduct((prev) => ({
                ...prev,
                tempAttributeName: "",
                tempAttributeValueName: "",
                tempAttributeId: null,
              }));

              handleModalActive();
            }}
            style={{
              backgroundColor: "#0077B6",
              color: "white",
            }}
          >
            Add Attribute
          </Button>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddProduct;

function Offsymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      No
    </div>
  );
}

function OnSymbol() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      Yes
    </div>
  );
}
