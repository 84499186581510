import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import toastr from "toastr";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { del, get, post } from "../../helpers/api_helper";
import ImageAndUrlHandler from "../../components/AddNewSliderImage";
import AddProductToPopularList from "../../components/AddProductToPopularList";
import SearchableDropdown from "../../components/SearchableDropdown";

const AddNewOfferPageWebSecond = () => {
  const navigation = useHistory();
  const [pageTitle, setPageTitle] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [apicallTime, setApiCallTime] = useState(false);

  const [products, setProducts] = React.useState([]);
  React.useEffect(() => {
    getProducts();
  }, []);
  async function getProducts() {
    const products = await post("/api/content-management/add-products", {
      query: "",
    });
    setProducts(
      products.map((el) => ({ ...el, label: el.name, value: el.id }))
    );
  }
  async function savePage() {
    try {
      if (!pageTitle.trim()) {
        toastr.error("Please enter page title");
        return;
      }
      if (!imageFile) {
        toastr.error("Please select an image");
        return;
      }
      if (!selectedProducts.length) {
        toastr.error("Please select some products");
        return;
      }

      const formData = new FormData();

      formData.append("sliderImage", imageFile);

      formData.append(
        "objectDetails",
        JSON.stringify({
          pageTitle,
          // products,
          products: selectedProducts,
        })
      );

      const config = {
        method: "POST",
        body: formData,
      };
     setApiCallTime(true);
      // Send the FormData to the server
      const responseObj = await fetch(
        `${process.env.REACT_APP_AUTHDOMAIN}/api/create-banner-image-page-second`,
        config
      );
      const res = await responseObj.json();
      if (!res?.status) {
        toastr.error("Something went wrong");
        return;
      }
      setApiCallTime(false)
      navigation.push("/content-management-website");
    } catch (e) {
      console.log(e);
    }
  }
  const handleImageFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && isImageType(selectedFile)) {
      setImageFile(
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile),
        })
      );
    } else {
      alert("Please select a valid image file (JPEG, PNG)");
    }
  };
  const isImageType = (file) => {
    const imageTypes = ["image/jpeg", "image/png"];
    return imageTypes.includes(file.type);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Add New Page Web"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Add New Page Web", link: "#" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="mb-3">
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="pageTitleWeb">
                      Page Title
                    </Label>
                    <Input
                      value={pageTitle}
                      onChange={(e) => setPageTitle(e.target.value)}
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <Label>{`Banner Image`}</Label>
                  <FormGroup>
                    <Input
                      type="file"
                      name="file"
                      id="category"
                      className="mb-3"
                      onChange={handleImageFileChange}
                    />
                    <img src={imageFile?.preview} width="150px"  alt=""/>
                  </FormGroup>

                  <Label>Add Products to Page</Label>
                  <Col md={4}>
                    <SearchableDropdown
                      options={products.filter(
                        (product) =>
                          !selectedProducts.some(
                            (selected) => selected.id === product.id
                          )
                      )}
                      onSelect={(item) => {
                        setSelectedProducts((prev) => [...prev, item]);
                      }}
                    />
                  </Col>

                  {selectedProducts?.length
                    ? selectedProducts.map((item) => {
                        return (
                          <Row
                            md={6}
                            style={{
                              marginTop: "10px",
                              border: "1px solid #E3E3E3",
                              color: "#5E6363",
                              padding: "5px",
                            }}
                          >
                            <Col
                              md={6}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <b>{item?.name}</b>
                            </Col>
                            <Col md={6}>
                              <Button
                                color="primary"
                                className="primary"
                                onClick={() => {
                                  setSelectedProducts(
                                    selectedProducts.filter(
                                      (elem) => elem.id !== item.id
                                    )
                                  );
                                }}
                              >
                                <i className="mdi mdi-delete font-size-14"></i>
                              </Button>
                            </Col>
                          </Row>
                        );
                      })
                    : null}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Button
                      style={{ marginTop: "30px" }}
                      color="primary"
                      className="primary"
                      onClick={savePage}
                    >
                      Save
                    </Button> */}
                    <Button
                      color="primary"
                      style={{ marginTop: "30px" }}
                      disabled={apicallTime}
                      className="primary"
                      onClick={savePage}
                    >
                      {apicallTime ? (
                        <Spinner size="sm">Save...</Spinner>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" isOpen={false} toggle={() => {}}>
        <ModalHeader toggle={() => {}}>Add New Vendor</ModalHeader>
        <ModalBody></ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddNewOfferPageWebSecond;
