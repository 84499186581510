import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { del, post } from "../helpers/api_helper";
import toastr from "toastr";
// import { shipmentInquiryButton } from "../../store/layout/actions";
import { shipmentInquiryButton } from "../store/layout/actions"
import { useDispatch } from "react-redux";
function PackageEnquiryActionDropdown({ row, getAllCategories, activeTab }) {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
 
  async function updateVendorRequestStatus(bool, data) {
    try {
      if (bool) {
        await post(`/api/create-package-enquiry`, data);
      } else {
        await del(`/api/package-enquiry/${row.id}`, {});
      }
      toastr.success("Status updated!", "Success");
      getAllCategories();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
  const handleChangeStatusClick = () =>{
    dispatch(shipmentInquiryButton(true));
  }


  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#000">
      <DropdownToggle className="bg-primary" caret onClick={handleChangeStatusClick}>
        CHANGE STATUS
      </DropdownToggle>
      <DropdownMenu>
        {activeTab === "PENDING" ||activeTab === "ALL" && (
            <DropdownItem
              onClick={() => {const data = { ...row, status: "PENDING" };updateVendorRequestStatus(true, data)}}>
              <i className="mdi mdi-account-edit font-size-18 me-2"></i>PENDING
            </DropdownItem>
          )}
        {(activeTab === "PENDING" || activeTab === "ALL") && (
            <DropdownItem
              onClick={() => {const data = { ...row, status: "WORK_IN_PROGRESS" };updateVendorRequestStatus(true, data)}}>
              <i className="mdi mdi-account-edit font-size-18 me-2"></i>WORK IN PROGRESS
            </DropdownItem>
          )}
        {(activeTab === "PENDING" || activeTab === "WORK_IN_PROGRESS" || activeTab === "ALL") && (
          <DropdownItem
            onClick={() => {const data = { ...row, status: "RESOLVED" };updateVendorRequestStatus(true, data)}}>
            <i className="mdi mdi-pencil font-size-18 me-2"></i>RESOLVED
          </DropdownItem>
        )}
        {activeTab !== "CLOSED" && (
          <DropdownItem
            onClick={() => {const data = { ...row, status: "CLOSED" };updateVendorRequestStatus(true, data)}}>
            <i className="mdi mdi-close font-size-18 me-2"></i>CLOSED
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

export default PackageEnquiryActionDropdown;