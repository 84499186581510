import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";

import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import toastr from "toastr";

const WithdrawalAdmin = () => {
  const [viewModal, setViewModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [activeTab, setActiveTab] = useState("PENDING");
  const [bankDetails, setBankDetails] = useState(false);
  const user = useSelector((state) => state.userAuthInfo.user);
  const handleAddVendorModal = () => {
    setViewModal((prev) => !prev);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    getWithdrawalRequests();
  }, [activeTab, user]);

  async function getWithdrawalRequests() {
    try {
      const content = await post("/api/admin/withdrawal", {
        status: activeTab,
      });
      setOrders(content);
    } catch (e) {
      console.log(e);
    }
  }

  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: orders.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  // const selectRow = {
  //   mode: "checkbox",
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Withdrawal Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Withdrawal Management", link: "/vendor-withdarawal" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "PENDING" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("PENDING")}
                              >
                                REQUESTS
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "ACCEPTED" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("ACCEPTED")}
                              >
                                PAID
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          getWithdrawalRequests,
                          setBankDetails,
                          handleAddVendorModal,
                          activeTab
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8"></Col>
                            </Row>
                            <div className="table-responsive" style={{height:"500px"}}>
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" isOpen={viewModal} toggle={handleAddVendorModal}>
        <ModalHeader toggle={handleAddVendorModal}>
          Vendor Bank Details
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="bankname">Bank Name</Label>
                <Input
                  type="text"
                  name="bankname"
                  id="bankname"
                  placeholder="Enter bank name"
                  value={bankDetails?.bank_name}
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="accountholdername">Account Holder Name</Label>
                <Input
                  type="text"
                  name="accountholdername"
                  id="accountholdername"
                  placeholder="Enter account holder name"
                  value={bankDetails?.account_holder_name}
                  disabled={true}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="accno">Account Number</Label>
                <Input
                  type="text"
                  name="accno"
                  id="accno"
                  placeholder="Enter Account Number"
                  value={bankDetails?.account_number}
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="ifsc">IFSC code</Label>
                <Input
                  type="text"
                  name="accountholdername"
                  id="ifsc"
                  placeholder="Enter IFSC"
                  value={bankDetails?.ifsc_code}
                  disabled={true}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default WithdrawalAdmin;
