// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  CHANGE_ACTIVE_ECOMMERCE_TAB,
  VENDOR_ACTIVE_TABS,
  FORM_PAGE_TITLE,
  UMBRELLA_API_CALL_STATE,
  SUPPORT_DATA_PASS,
  SHIPMENT_INQUIRY_BUTTON,
  CUSTOMER_SUPPORT,
  VARIANT_VALIDATION_CHECK,
  VENDOR_CHANGE_PASSWORD,
} from "./actionTypes";

const INIT_STATE = {
  layoutType: "vertical",
  layoutWidth: "fluid",
  leftSideBarTheme: "dark",
  leftSideBarType: "default",
  topbarTheme: "light",
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  activeEcommerceTab: "PLACED",
  vendorActiveTab: "PLACED",
  formPageTitle: "Create Order",
  umbrellaApiCallState: false,
  SUPPORT_DATA_PASS: "",
  SHIPMENT_INQUIRY_BUTTON: false,
  CUSTOMER_SUPPORT: "",
  VARIANT_VALIDATION_CHECK: false,
  VENDOR_CHANGE_PASSWORD : false,
};

const Layout = (state = INIT_STATE, action) => {
	switch (action.type) {

    case SHIPMENT_INQUIRY_BUTTON : 
    return {
      ...state,
      SHIPMENT_INQUIRY_BUTTON : action.payload, 
    };

    case VARIANT_VALIDATION_CHECK : 
     return {
       ...state,
       VARIANT_VALIDATION_CHECK : action.payload
     };

    case CUSTOMER_SUPPORT :
      return {
        ...state,
        CUSTOMER_SUPPORT : action.payload, 
      };

    case SUPPORT_DATA_PASS : 
    return {
      ...state,
      SUPPORT_DATA_PASS : action.payload
    }
    case CHANGE_ACTIVE_ECOMMERCE_TAB:
      return {
        ...state,
        activeEcommerceTab: action.payload,
      };
    case VENDOR_ACTIVE_TABS:
      return{
        ...state,
        vendorActiveTab : action.payload
      }
    case FORM_PAGE_TITLE :{
      return {
        ...state,
        formPageTitle : action.payload
      };
    }
    case UMBRELLA_API_CALL_STATE : {
      return {
        ...state,
        umbrellaApiCallState : action.payload,
      };
    }
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload.width,
      };
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload.theme,
      };
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      };
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      };
    case HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      };
    case VENDOR_CHANGE_PASSWORD :
      return {
        ...state,
        vendor_password_change : false
      }
    default:
      return state;
  }
};

export default Layout;
