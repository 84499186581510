import {
  ADD_USER_INFO,
  REMOVE_USER_INFO,
  SELECTED_TICKET,
  SELECT_CATEGORY,
  SELECT_PRODUCT,
  SET_ACTIVE_TAB,
  SELECTED_MESSAGES,
  SET_NEW_CUSTOMER_MESSAGE
} from "./actionTypes";

export const addUserInfo = (user) => {
    return {
        type: ADD_USER_INFO,
        payload: user
    }
}

export const removeUserInfo = () => {
    return {
        type: REMOVE_USER_INFO,
        payload: null
    }
}

export const selectCategoryToUpdate = (info) => {
    return {
        type: SELECT_CATEGORY,
        payload: info
    }
}

export const selectProductToUpdate = (info) => {
    return {
        type: SELECT_PRODUCT,
        payload: info
    }
}

export const selectedTicketToViewChat = (info) => {
    return {
        type: SELECTED_TICKET,
        payload: info
    }
}

export const selectedSupportMessageCustomer = (info) => {
  return {
    type: SELECTED_MESSAGES,
    payload: info,
  };
};

export const setActiveTab = (tab) => {
  return {
    type: SET_ACTIVE_TAB,
    payload: tab,
  };
};

export const customerMessages = (message) =>{
  return {
    type: SET_NEW_CUSTOMER_MESSAGE,
    payload : message
  };
}