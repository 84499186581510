import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styles from "./chat.module.css";
import { Container, Row, Col, Input, Button } from "reactstrap";
import { post } from "../../helpers/api_helper";
import toastr from "toastr";
const VendorTicketChat = () => {
  const navigation = useHistory();
  const user = useSelector((state) => state.userAuthInfo.user);
  const fileInputRef = React.useRef(null);
  const [selectedFiles, setSelectedFile] = useState([]);
  const selectedticketToViewChat = useSelector(
    (state) => state.userAuthInfo.selectedticketToViewChat
  );
  const handleFileButtonClick = () => {
    fileInputRef.current.click();    
  };
  const [message, setMessage] = useState("");
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
     if (!file) return; 
    const formData = new FormData();
    formData.append("attachment", file);
    formData.append(
      "objectDetails",
      JSON.stringify({
        message: message || "Attachment",
        ticket_id: selectedticketToViewChat?.id,
        ...(user?.isAdmin && { is_replied_by_admin: true }),
      })
    );
    const config = {
      method: "POST",
      body: formData,
    };
    const responseObj = await fetch(
      `${process.env.REACT_APP_AUTHDOMAIN}/api/tickets/add-message`,
      config
    );
    const res = await responseObj.json();
    if (!res?.status) {
      toastr.error(res.message);
      return;
    }
    setMessage("");
    toastr.success("Message sent!", "Success");
    getAllTicketMessages();
    window.setTimeout(() => {
      myDivRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };
  const myDivRef = React.useRef(null);
  function downloadFile(url) {
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = url;
    anchor.download = "";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    if (!selectedticketToViewChat) {
      if (user?.isAdmin) {
        navigation.push("/dashboard");
      } else {
        navigation.push("/vendor-dashboard");
      }
    } else {
      getAllTicketMessages();
    }
  }, []);
  useEffect(() => {
    window.setTimeout(() => {
      myDivRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  }, []);
  async function getAllTicketMessages() {
    const infoMessages = await post(
      "/api/tickets/get-all-vendor-ticket-messages",
      {
        ticket_id: selectedticketToViewChat?.id,
      }
    );
    setMessages(infoMessages);
  }
  async function sendMessage() {
    try {
      if (!message) {
        toastr.error("Please type a message to send");
        return;
      }
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        if (!selectedFiles[i]?.oldImage) {
          formData.append("attachment", selectedFiles[i]);
        }
      }
      formData.append(
        "objectDetails",
        JSON.stringify({
          message: message,
          ticket_id: selectedticketToViewChat?.id,
          ...(user?.isAdmin && { is_replied_by_admin: true }),
        })
      );
      const config = {
        method: "POST",
        body: formData,
      };
      const responseObj = await fetch(
        `${process.env.REACT_APP_AUTHDOMAIN}/api/tickets/add-message`,
        config
      );
      const res = await responseObj.json();
      if (!res?.status) {
        toastr.error(res.message);
        return;
      }
      setMessage("");
      toastr.success("Message sent!", "Success");
      getAllTicketMessages();
      window.setTimeout(() => {
        myDivRef?.current?.scrollIntoView({ behavior: "smooth" });
      }, 200);
    } catch (e) {
      console.log(e);
    }
  }
  async function closeTicket() {
    await post(`/api/tickets/close-ticket`, {
      ticket_id: selectedticketToViewChat?.id,
    });
    if (user?.isAdmin) {
      navigation.push("/admin-queries");
    } else {
      navigation.push("/support");
    }
  }
  return (
    <div className="page-content">
      <Container fluid>
        {user?.isAdmin && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              disabled={selectedticketToViewChat?.ticket_status === "CLOSED"}
              color="primary"
              onClick={closeTicket}
            >
              Close Ticket
            </Button>
          </div>
        )}
        <Container
          className={styles.chatContainer}
          style={{ width: "100%", height: "100%", maxWidth: "1700px" }}
        >
          <Row className={styles.chatMessages}>
            <Col md={{ size: 12, offset: -1 }}>
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`${styles.chatMessage} ${
                    message?.is_replied_by_admin
                      ? styles[`user`]
                      : styles[`other`]
                  }`}
                >
                  <p
                    className={
                      message?.is_replied_by_admin
                        ? styles.titleAdmin
                        : styles.title
                    }
                  >
                    {message?.is_replied_by_admin
                      ? `Admin:`
                      : `${message.vendorName} :`}
                  </p>
                  {message?.type === "FILE" ? (
                    <>
                      <div
                        onClick={() => {
                          downloadFile(
                            `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${message?.message}`
                          );
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: message?.message?.replace(
                              /color:\s*rgb\(0,\s*0,\s*0\)/g,
                              message?.is_replied_by_admin
                                ? "color:black"
                                : "color: white"
                            ),
                          }}
                        ></div>
                        <span style={{ marginLeft: "5px" }}>
                          <i className="mdi mdi-download font-size-18 me-2"></i>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: message?.message?.replace(
                              /color:\s*rgb\(0,\s*0,\s*0\)/g,
                              message?.is_replied_by_admin
                                ? "color:black"
                                : "color: white"
                            ),
                          }}
                        ></div>

                        <span style={{ marginLeft: "5px" }}></span>
                      </div>
                    </>
                  )}
                  <p
                    className={
                      message?.is_replied_by_admin
                        ? styles.createdAtAdmin
                        : styles.createdAt
                    }
                  >
                    {new Date(message?.createdAt)?.toLocaleString()}
                  </p>
                </div>
              ))}
              <div ref={myDivRef} />
            </Col>
          </Row>
          {selectedticketToViewChat?.ticket_status === "CLOSED" ? (
            ""
          ) : (
            <Row>
              <Col md={{ size: 6, offset: 2 }}>
                <Input
                  type="text"
                  placeholder="Type your message..."
                  value={message}
                  disabled={
                    selectedticketToViewChat?.ticket_status === "CLOSED"
                  }
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Col>
              <Col md="3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Button
                    disabled={
                      selectedticketToViewChat?.ticket_status === "CLOSED"
                    }
                    color="primary"
                    onClick={sendMessage}
                  >
                    Send
                    <i
                      className="mdi mdi-send"
                      style={{ marginLeft: "10px" }}
                    ></i>
                  </Button>
                  <Button
                    disabled={
                      selectedticketToViewChat?.ticket_status === "CLOSED"
                    }
                    color="primary"
                    onClick={handleFileButtonClick}
                  >
                    Attachment
                  </Button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Container>
    </div>
  );
};
export default VendorTicketChat;