import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import toastr from "toastr";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Editor from "react-simple-wysiwyg";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  ModalFooter,
  Form,
} from "reactstrap";

import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
const UsersComponent = () => {
  const [viewModal, setViewModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [dataObject, setDataObject] = useState({});

  const [orders, setOrders] = useState([]);
  const [activeTab, setActiveTab] = useState("ACTIVE");
  const [errors, setErrors] = useState([]);
  const [searchString, setSearchString] = useState("");

  const user = useSelector((state) => state.userAuthInfo.user);
  const handleAddVendorModal = () => {
    setViewModal((prev) => !prev);
  };
  const handleShowImageModal = () => {
    setShowImageModal((prev) => !prev);
  };
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  async function saveUser() {
    try {
      setErrors([]);
      if (dataObject.id) {
        dataObject.id = dataObject?.id;
      }
      await post("/api/auth/create-user-admin", { ...dataObject });
      getAllVendorRequests();
      toastr.success("Success");
      handleAddVendorModal();
    } catch (e) {
      if (e?.response?.data?.errors) {
        console.log(e);

        setErrors(e?.response?.data?.errors);
      }
      toastr.error(e?.response?.data?.message || "something went wrong");
    }
  }
  useEffect(() => {
    getAllVendorRequests();
  }, [activeTab, user]);
  async function getAllVendorRequests() {
    try {
      const content = await post("/api/auth/get-all-users-admin", {
        status: activeTab,
      });

      setOrders(content);
    } catch (e) {
      console.log(e);
    }
  }

  const downloadExcel = (data, filename) => {
    const users = data.map((item, index) => ({
      No: index + 1,
      UserId: item.user_id,
      first_name: item?.first_name,
      last_name: item?.last_name,
      // mobile_number: `${item?.country_code}-${item?.mobilenumber}`,
      mobile_number: item.mobilenumber
        ? item.country_code + item.mobilenumber
        : "Email",
      email: item?.email,
      status: `${item?.user_status}`,
    }));

    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate a binary string from the workbook
    const excelBinaryString = XLSX.write(wb, {
      bookType: "xlsx",
      type: "binary",
    });

    // Convert the binary string to a Blob
    const excelBlob = new Blob([s2ab(excelBinaryString)], {
      type: "application/octet-stream",
    });

    // Save the Blob as a file
    saveAs(excelBlob, filename);
  };
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: orders.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  // const selectRow = {
  //   mode: "checkbox",
  // };

  const handleObjectChange = (event) => {
    const { name, value } = event.target;
    const regex = /^[a-zA-Z\s]*$/;
    if (name === "first_name" || name === "last_name") {
      if (regex.test(value)) {
        setDataObject((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setDataObject((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const [displayModal, setDisplayModal] = useState(false);
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  useEffect(() => {
    if (selectedUserEmail !== "") {
      setDisplayModal((prev) => !prev);
    }
  }, [selectedUserEmail]);
  function toggleModal() {
    setDisplayModal((prev) => !prev);
  }
  const [emailTemplate, setEmailTemplate] = useState({
    subject: "",
    message: "",
  });
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  async function sendEmailToCustomer() {
    try {
      //  const formData = new FormData();
      //  formData.append("email", selectedUserEmail?.email);
      //  formData.append("subject", emailTemplate?.subject);
      //  formData.append("message", emailTemplate?.message);

      //  const config = {
      //    method: "POST",
      //    body: formData,
      //  };

      let body = { ...emailTemplate, selectedUserEmail };

      let responseObj = await post("/api/send-email-to-user", body);

      //  const responseObj = await fetch(
      //    `${process.env.REACT_APP_AUTHDOMAIN}/api/send-email-to-user`,
      //    config
      //  );
      const data = await responseObj;
      if (responseObj.status === 400) {
        return toastr.error(data?.message);
      }

      toastr.success("Success!");
      toggleModal();
      //  getEmailTemplates();
    } catch (e) {
      toastr.error(e?.response?.data?.message, "Error");
      console.log(e);
    }
  }
  //  async function getEmailTemplates() {
  //    try {
  //      const content = await get("/api/email-template");
  //      setEmailTemplateList(content?.emailTemplates || []);
  //    } catch (e) {
  //      toastr.error(e?.response?.data?.message, "Error");
  //    }
  //  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Users Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Users Management", link: "/users" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <Nav tabs>
                            <NavItem></NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "ACTIVE" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("ACTIVE")}
                              >
                                ACTIVE
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={`${
                                  activeTab === "SUSPEND" ? "active" : ""
                                }`}
                                onClick={() => toggleTab("SUSPEND")}
                              >
                                SUSPENDED
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        exportCSV={true}
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          getAllVendorRequests,
                          setDataObject,
                          handleAddVendorModal,
                          activeTab,
                          setSelectedUserEmail
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      value={searchString}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={() => {
                                      downloadExcel(orders, "Users.xlsx");
                                    }}
                                  >
                                    <i className="mdi mdi-forward me-1" />{" "}
                                    Export to XLSX
                                  </Button>
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={() => {
                                      setDataObject({});
                                      handleAddVendorModal();
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Add New
                                    User
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ height: "500px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" isOpen={viewModal} toggle={handleAddVendorModal}>
        <ModalHeader toggle={handleAddVendorModal}>
          {dataObject?.id ? `Update` : `Create`} User
        </ModalHeader>
        <ModalBody>
          <>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="first_name">
                    First Name
                  </Label>
                  <Input
                    id="first_name"
                    name="first_name"
                    type="text"
                    value={dataObject?.first_name || ""}
                    onChange={handleObjectChange}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="last_name">
                    Last Name
                  </Label>
                  <Input
                    id="last_name"
                    name="last_name"
                    type="text"
                    value={dataObject?.last_name || ""}
                    onChange={handleObjectChange}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="email">
                    Email
                  </Label>
                  <Input
                    id="email"
                    name="email"
                    type="text"
                    value={dataObject?.email || ""}
                    onChange={handleObjectChange}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="password">
                    Password
                  </Label>
                  <Input
                    id="password"
                    name="password"
                    type="text"
                    onChange={handleObjectChange}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="country_code">
                    Country Code
                  </Label>
                  <select
                    id="country_code"
                    name="country_code"
                    value={dataObject?.country_code || ""}
                    onChange={handleObjectChange}
                    className="form-control"
                  >
                    <option value="" disabled>
                      Select Country Code
                    </option>
                    <option value="+1">+1 (Canada)</option>
                    <option value="+91">+91 (India)</option>
                  </select>
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="mobilenumber">
                    Mobile Number
                  </Label>
                  <Input
                    id="mobilenumber"
                    name="mobilenumber"
                    type="tel"
                    maxLength="10"
                    value={dataObject?.mobilenumber?.replace(/\D/g, "") || ""}
                    onChange={handleObjectChange}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>

            <ul style={{ color: "red", listStyleType: "none", padding: 0 }}>
              {errors.map((error, index) => (
                <>
                  <li key={index}>
                    {" "}
                    <span role="img" aria-label="Error Icon">
                      ⚠️
                    </span>{" "}
                    {error}
                  </li>
                </>
              ))}
            </ul>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "10%",
                margin: "auto",
              }}
            >
              <Button
                style={{ marginTop: "30px" }}
                color="primary"
                className="primary"
                onClick={saveUser}
              >
                Save
              </Button>
            </div>
          </>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={displayModal}
        role="document"
        autoFocus={true}
        centered={true}
        className="composemodal"
        tabIndex="-1"
        toggle={toggleModal}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleModal}>Send Email</ModalHeader>
          <ModalBody>
            <Form>
              {/* <div className="mb-3">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  value={emailTemplate?.title}
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }));
                  }}
                />
              </div> */}

              <div className="mb-3">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  value={emailTemplate?.subject}
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      subject: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-3">
                <Editor
                  containerProps={{ style: { height: "200px" } }}
                  value={emailTemplate?.message}
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      message: e.target.value,
                    }));
                  }}
                />
              </div>

              {/* <div className="mb-3">
                <Input
                  type="file"
                  className="form-control"
                  placeholder="Image"
                  onChange={(e) => {
                    setEmailTemplate((prev) => ({
                      ...prev,
                      image: e.target.files[0],
                    }));
                  }}
                />
              </div> */}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button coloe="secondary" type="button" onClick={toggleModal}>
              Close
            </Button>
            <Button onClick={sendEmailToCustomer} type="button" color="primary">
              Save <i className="fab fa-telegram-plane ms-1"></i>
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      <Modal size="xl" isOpen={showImageModal} toggle={handleShowImageModal}>
        <ModalHeader toggle={handleShowImageModal}>Order Info</ModalHeader>
        <ModalBody></ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UsersComponent;
