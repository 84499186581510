import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { Card, CardBody, Col, Container, Row} from "reactstrap";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post } from "../../helpers/api_helper";
import toastr from "toastr";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  Table,
} from "reactstrap";
const SupportAdmin = () => {
  const navigation = useHistory()
    const dispatch = useDispatch();
  const [messages,setMessages] = useState([])
  // code start

  const [dataObject, setDataObject] = useState();
    const [modalShow, setModalShow] = useState(false);
    const handleModalShow = () => {
      setModalShow((prev) => !prev);
    };
  
  const handleObjectChange = (event) => {
    const { name, value } = event.target;
    if (parseInt(value) < 0) {
      toastr.error("Negative values are not allowed.");
      return;
    }
    // setDataObject((prev) => ({
    //   ...prev,
    //   [name]: value ? parseInt(value) : 0,
    // }));
  };
    const supportDashBoardData = useSelector((state) => state.Layout.SUPPORT_DATA_PASS);
    // if (supportDashBoardData) {
    //   setModalShow((prev) => !prev);
    // }
    
useEffect(() => {
  if (supportDashBoardData !== "") {
    setModalShow((prev) => !prev);
    setDataObject(supportDashBoardData);
  }
}, [supportDashBoardData]);

  const [loading, setLoading] = useState(false);

  async function handleNewDataObjectCreation() {
    if (!dataObject?.shipping_charges_type) {
      toastr.error("shipping type is required");
      return;
    }
    if (!dataObject?.Order_charges_type) {
      toastr.error("order type is required");
      return;
    }
    try {
      setLoading(true);
      await post("/api/shipping-charges-create", dataObject);

      handleModalShow();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toastr.error(e?.response?.data?.message || "something went wrong");
    }
  }

  // code over
  const [shipmentType, setShipmentType] = useState("Shipment");

  async function getSupportMessage(shipmentType) {
    try {
      const content = await post("/api/support/get-all-customer-supports",{
        module : shipmentType
      });
      setMessages(content);
    } catch (e) {
      console.log(e);
    }
  }
    useEffect(() => {
      if(shipmentType){
        getSupportMessage(shipmentType);
      }
    }, [shipmentType]);

  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: messages?.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  // const selectRow = {
  //   mode: "checkbox",
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Support"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Support", link: "/sub-categories" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <Row className="mb-3">
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={`${
                                shipmentType === "Shipment" ? "active" : ""
                              }`}
                              onClick={() => setShipmentType("Shipment")}
                              style={{ cursor: "pointer" }}
                            >
                              PRIVATE SHIPMENT
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={`${
                                shipmentType === "Ecom Shipment" ? "active" : ""
                              }`}
                              onClick={() => setShipmentType("Ecom Shipment")}
                              style={{ cursor: "pointer" }}
                            >
                              ECOM SHIPMENT
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={`${
                                shipmentType === "Marketplace" ? "active" : ""
                              }`}
                              onClick={() => setShipmentType("Marketplace")}
                              style={{ cursor: "pointer" }}
                            >
                              MARKETPLACE
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={messages}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={messages}
                        columns={EcommerceOrderColumns(
                          getSupportMessage,
                          messages,
                          setDataObject,
                          handleModalShow,
                          navigation,
                          dispatch
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ minHeight: "200px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Modal size="xl" isOpen={modalShow} toggle={handleModalShow}>
        <ModalHeader toggle={handleModalShow}>Support</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_name">
                  FullName
                </Label>
                <Input
                  id="fullName"
                  name="fullName"
                  type="text"
                  value={dataObject?.fullName}
                  className="form-control"
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_length">
                  Email
                </Label>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  value={dataObject?.email}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_name">
                  Phone number
                </Label>
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="number"
                  value={dataObject?.phoneNumber}
                  className="form-control"
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_length">
                  Subject
                </Label>
                <Input
                  id="subject"
                  name="subject"
                  type="text"
                  value={dataObject?.subject}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_name">
                  Reason
                </Label>
                <Input
                  id="reason"
                  name="reason"
                  type="text"
                  value={dataObject?.reason}
                  className="form-control"
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_length">
                  Type Of Query
                </Label>
                <Input
                  id="type_of_query"
                  name="type_of_query"
                  type="text"
                  value={dataObject?.type_of_query}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_name">
                  Module
                </Label>
                <Input
                  id="module"
                  name="module"
                  type="text"
                  value={dataObject?.module}
                  className="form-control"
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="size_length">
                  Message
                </Label>
                <Input
                  id="message"
                  name="message"
                  type="text"
                  value={dataObject?.message}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal> */}
    </React.Fragment>
  );
};
export default SupportAdmin;




