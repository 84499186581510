import React from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { useSelector } from "react-redux";
import { get, post, del } from "../../helpers/api_helper";
import toastr from "toastr";
const VendorStore = () => {
  const navigation = useHistory();
  const [sliderImages, setSliderImages] = React.useState([]);
  const [logo, setLogo] = React.useState([]);
  
  let vendorData = JSON.parse(localStorage.getItem("auth_info"));

  React.useEffect(() => {
    fetchVendorSliderImages();
    fetchVendorLogo();
  }, []);

  async function fetchVendorSliderImages() {
    try {
      const response = await post("/api/vendor-store-banner-images", {
        vendor_id: vendorData?.id,
      });
      if (response?.allBannerImages) {
        setSliderImages(response?.allBannerImages);
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function fetchVendorLogo() {
    try {
      const response = await post("/api/vendor-store-logo", {
        vendor_id: vendorData?.id,
      });
      if (response?.logo) {
        setLogo(response?.logo);
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function deleteSliderImage(id) {
    await post(`/api/vendor-slider-image-delete`, { id });
    fetchVendorSliderImages();
  }
  async function handleViewStoreClick() {
    window.open(
      `http://localhost:3001/store/${vendorData?.username}`,
      "_blank",
      "noopener,noreferrer"
    );
  }

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Vendor Store"
              breadcrumbItems={[
                { title: "Dashboard", link: "/dashboard" },
                { title: "Vendor Store", link: "#" },
              ]}
            />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <h3>Banner Images</h3>
                    {sliderImages?.length ? (
                      <>
                        {sliderImages?.map((item) => {
                          return (
                            <Row style={{ marginTop: "5px" }} key={item?.id}>
                              <Col md={3}>
                                <img
                                  height={50}
                                  src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image_url}`}
                                />
                              </Col>
                              <Col
                                md={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <b>{item?.pageTitle}</b>
                              </Col>
                              <Col md={3}>
                                <Button
                                  color="primary"
                                  className="primary"
                                  onClick={() => {
                                    deleteSliderImage(item?.id);
                                  }}
                                >
                                  <i className="mdi mdi-delete font-size-18"></i>
                                </Button>
                              </Col>
                            </Row>
                          );
                        })}
                      </>
                    ) : (
                      <div>No Banner Images Added Yet!</div>
                    )}
                    <Button
                      style={{ marginTop: "30px" }}
                      color="primary"
                      className="primary"
                      onClick={() => {
                        navigation.push("/add-new-slider-vendor-store");
                      }}
                    >
                      Add New Banner
                    </Button>
                    <Button
                      style={{ marginTop: "30px", marginLeft: "20px" }}
                      color="primary"
                      className="primary"
                      onClick={handleViewStoreClick}
                    >
                      View Store
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <h3>Logo</h3>
                    {logo.image_url ? (
                      <>
                            <Row style={{ marginTop: "5px" }} key={logo?.id}>
                              <Col md={3}>
                                <img
                                  height={50}
                                  src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${logo?.image_url}`}
                                />
                              </Col>
                            </Row>
                      </>
                    ) : (
                      <div>No Logo Added Yet!</div>
                    )}
                    <Button
                      style={{ marginTop: "30px" }}
                      color="primary"
                      className="primary"
                      onClick={() => {
                        navigation.push("/add-logo-vendor-store");
                      }}
                    >
                      {/* Add New Logo */}
                      {logo.image_url ? "Update Logo" : "Add New Logo"}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default VendorStore;
